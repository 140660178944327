import React, { useEffect, useState } from "react";
import { cleanInput } from "../../../utils/functions";
import { useAtom, useSetAtom } from "jotai";
import {
  newCampaignElementsAtom,
  newCampaignSetupStatusAtom,
} from "../../../atoms/CampaignAtom";
import { useLocation, useNavigate } from "react-router-dom";
import { previousPageAtom } from "../../../atoms/PreviousPageAtom";
import SetupPhishingTemplate from "./components/SetupPhishingTemplate";
import SetupEmailsList from "./components/SetupEmailsList";
import SetupSchedule from "./components/SetupSchedule";
import SetupAcceptance from "./components/SetupAcceptance";
import MissingInputs from "./components/MissingInputs";

const CampaignSetUpV2 = () => {
  const [newCampaignElements, setNewCampaignElements] = useAtom(
    newCampaignElementsAtom
  );
  const redirect = useNavigate();
  const [minLaunchDate, setMinLaunchDate] = useState("");
  const setPreviousPage = useSetAtom(previousPageAtom);
  const location = useLocation();
  const [newCampaignSetupStatus, setNewCampaignSetupStatus] = useAtom(
    newCampaignSetupStatusAtom
  );
  const [areInputsMissing, setAreInputsMissing] = useState(false);

  useEffect(() => {
    // functions that checks if all required inputs to set up a campaigns are filled up or not, in order to display these status to user, in order for him to know what's missing.
    const resetRequiredInputsChecking = () => {
      setNewCampaignSetupStatus({
        phishingTemplate: "/static/media/mailbox.b0196950cf0520d2da68.png",
        emailsList: false,
        name: false,
        launchDate: false,
        launchHour: false,
        acceptance: false,
      });
    };

    resetRequiredInputsChecking();

    // Need to parse this dates in order to disable not valid dates in the date inputs
    var minLaunchDateObject = {
      day: null,
      month: null,
      year: null,
    };

    const parseMinLaunchDate = () => {
      const firstLaunchDate = new Date();

      if (firstLaunchDate.getDate() < 10) {
        minLaunchDateObject.day = `0${firstLaunchDate.getDate()}`;
      } else {
        minLaunchDateObject.day = `${firstLaunchDate.getDate()}`;
      }

      if (firstLaunchDate.getMonth() + 1 < 10) {
        minLaunchDateObject.month = `0${firstLaunchDate.getMonth() + 1}`;
      } else {
        minLaunchDateObject.month = `${firstLaunchDate.getMonth() + 1}`;
      }

      minLaunchDateObject.year = firstLaunchDate.getFullYear();

      setMinLaunchDate(
        `${minLaunchDateObject.year}-${minLaunchDateObject.month}-${minLaunchDateObject.day}`
      );
    };

    parseMinLaunchDate();
  }, [setNewCampaignSetupStatus]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (requiredInputsChecking()) {
      setAreInputsMissing(true);
    } else {
      setAreInputsMissing(false);
      inputsProcessing(e);
    }
  };

  const requiredInputsChecking = () => {
    return !newCampaignSetupStatus.phishingTemplate ||
      !newCampaignSetupStatus.emailsList ||
      !newCampaignSetupStatus.name ||
      !newCampaignSetupStatus.launchDate ||
      !newCampaignSetupStatus.launchHour ||
      !newCampaignSetupStatus.acceptance
      ? true
      : false;
  };

  const inputsProcessing = (e) => {
    const launchDate = parseLaunchDate(e);

    setNewCampaignElements({
      name: cleanInput(e.target.campaignName.value),
      templateName: e.target.template.value,
      templateScreenshot: newCampaignElements.templateScreenshot,
      emailsList: newCampaignElements.emailsList,
      usersGroupName:
        cleanInput(e.target.campaignName.value) +
        "_liste" +
        Math.floor(Math.random() * 1000).toString(),
      launchDate: launchDate,
      launchTime:
        e.target.launchTime.value.split(":")[0] +
        "h" +
        e.target.launchTime.value.split(":")[1],
      acceptance: e.target.acceptance.value === "on" && true,
    });

    setPreviousPage(location.pathname);

    redirect("/campaign-control");
  };

  const parseLaunchDate = (e) => {
    const launchDateValue = e.target.launchDate.value;
    const launchTimeValue = e.target.launchTime.value;

    const [year, month, day] = launchDateValue.split("-");

    const [hours, minutes] = launchTimeValue.split(":");

    return new Date(year, month - 1, day, hours, minutes);
  };

  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div id="data-collect-container">
          <SetupPhishingTemplate />

          <SetupEmailsList />

          <SetupSchedule minLaunchDate={minLaunchDate} />

          <SetupAcceptance />

          <div className="margin-top-5vh">
            <button type="submit" className="blue-button">
              VALIDER
            </button>
          </div>

          {areInputsMissing && <MissingInputs />}
        </div>
      </form>
    </div>
  );
};

export default CampaignSetUpV2;
