import React, { useEffect, useState } from "react";
import {
  cleanInput,
  generateCaptcha,
  validateCaptcha,
  signUp,
} from "../../../../utils/functions";
import { useSetAtom } from "jotai/react";
import { passwordStrength } from "check-password-strength";
import { authUserAtom } from "../../../../atoms/AuthUserAtom";
import { useNavigate } from "react-router-dom";
import CguV2 from "../../../../components/CGU/CguV2";
import StrongPasswordInstructions from "./StrongPasswordInstructions";
import { ColorRing } from "react-loader-spinner";
import MainViewHeader from "../../../../components/MainViewTitle/MainViewTitle";
import Announcements from "../../../../components/Announcement/Announcements";

const SignUpFormV2 = ({ whichFormToDisplay }) => {
  const setAuthUserData = useSetAtom(authUserAtom);
  const redirect = useNavigate();

  const [displayPasswordInstructions, setDisplayPasswordInstructions] =
    useState(false);
  const [displayCGU, setDisplayCGU] = useState(false);

  const [captcha, setCaptcha] = useState();
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const [isPasswordStrong, setIsPasswordStrong] = useState(true);
  const [ArePasswordsSame, setArePasswordsSame] = useState(true);
  const [didSignUpFail, setDidSignUpFail] = useState(false);
  const [doesLoginAlreadyExist, setDoesLoginAlreadyExist] = useState(false);

  const [isSignupLoading, setIsSignupLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCaptcha(generateCaptcha());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const login = cleanInput(e.target.login.value);
    const password = cleanInput(e.target.password.value);
    const passwordConfirmation = cleanInput(
      e.target.passwordconfirmation.value
    );

    const checkCaptchaStatus = checkCaptcha(e);
    const checkPasswordStrengthStatus = checkPasswordStrength(password);
    const checkPasswordsMatchStatus = checkPasswordsMatch(
      password,
      passwordConfirmation
    );

    if (
      checkCaptchaStatus &&
      checkPasswordStrengthStatus &&
      checkPasswordsMatchStatus
    ) {
      signUp(
        login,
        password,
        setAuthUserData,
        redirect,
        setDidSignUpFail,
        setDoesLoginAlreadyExist,
        setIsSignupLoading
      );
    }
  };

  const checkCaptcha = (e) => {
    if (validateCaptcha(captcha, e.target.captcha.value)) {
      setIsCaptchaValid(true);
      return true;
    } else {
      setIsCaptchaValid(false);
      setCaptcha(generateCaptcha());
      return false;
    }
  };

  const checkPasswordStrength = (password) => {
    if (passwordStrength(password).value === "Strong") {
      setIsPasswordStrong(true);
      return true;
    } else {
      setIsPasswordStrong(false);
      return false;
    }
  };

  const checkPasswordsMatch = (password, passwordConfirmation) => {
    if (password === passwordConfirmation) {
      setArePasswordsSame(true);
      return true;
    } else {
      setArePasswordsSame(false);
      return false;
    }
  };
  return (
    <>
      <MainViewHeader iconToDisplay={"FaUsers"} textToDisplay={"S'INSCRIRE"} />

      <Announcements htmlCode={"Créez votre compte"} />

      <div className="signup-container">
        <div className="div-center width-fit-content text-center margin-top-1vh">
          <span
            className="font-size-075 cursor-pointer font-weight-bold"
            onClick={() => whichFormToDisplay("signin")}
          >
            Déjà inscrit ? Connectez-vous.
          </span>
        </div>

        <form onSubmit={(e) => handleSubmit(e)} className="signup-form">
          <div className="input-container-signinup margin-bottom-2vh">
            <input
              required
              maxLength="50"
              type="email"
              name="login"
              placeholder="Identifiant (email)"
              className="input-signinup"
            />
          </div>

          <div className="input-container-signinup margin-bottom-2vh">
            <input
              required
              minLength="12"
              maxLength="50"
              type="password"
              name="password"
              placeholder="Mot de passe"
              className="input-signinup"
            />
          </div>

          <div className="input-container-signinup margin-bottom-2vh">
            <input
              required
              minLength="12"
              maxLength="50"
              type="password"
              name="passwordconfirmation"
              placeholder="Confirmez mot de passe"
              className="input-signinup"
            />
          </div>

          {!ArePasswordsSame && (
            <>
              <span className="font-size-075 font-red font-weight-bold">
                Les mots de passe ne correspondent pas
              </span>
            </>
          )}

          {!isPasswordStrong && (
            <>
              <span className="font-size-075 font-red font-weight-bold">
                Mot de passe trop faible
              </span>
            </>
          )}
          <StrongPasswordInstructions
            displayPasswordInstructions={displayPasswordInstructions}
            setDisplayPasswordInstructions={setDisplayPasswordInstructions}
          />

          <div className="captcha-container">
            <div className="margin-right-0-5vw">
              CAPTCHA : recopiez <strong>{captcha}</strong>
              {!isCaptchaValid && (
                <>
                  <span className="mx-3 font-red font-weight-bold">
                    Erreur de captcha
                  </span>
                </>
              )}
            </div>
            <div className="border-bottom-1px-solid-grey">
              <input
                required
                type="text"
                maxLength={6}
                name="captcha"
                className="input-signinup"
              />
            </div>
          </div>

          <div className="cgu-validation-container">
            <div>
              <input required type="checkbox" className="margin-right-0-5vw" />
              <span className="font-size-075 margin-right-0-5vw">
                Cochez la case pour valider
              </span>
            </div>

            <div>
              <span
                className="cursor-pointer blue-badge margin-top-0-5vh div-center"
                onClick={() => setDisplayCGU(true)}
              >
                les conditions d'utilisation
              </span>
            </div>

            {displayCGU && (
              <>
                <CguV2 setDisplayCGU={setDisplayCGU} />
              </>
            )}
          </div>

          <div className="button-container margin-top-5vh">
            {isSignupLoading ? (
              <>
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#232233",
                    "#2b2c40",
                    "#232747",
                    "#5D87FF",
                    "#539BFF",
                  ]}
                />
              </>
            ) : (
              <>
                <button type="submit" className="blue-button">
                  OK
                </button>
              </>
            )}
          </div>

          <>
            <div className="margin-top-2vh">
              {doesLoginAlreadyExist && (
                <span className="font-size-075 font-red font-weight-bold">
                  Cet email ne peut pas être utilisé pour s'inscrire.
                </span>
              )}
              <br />
              {didSignUpFail && (
                <>
                  <span className="font-size-075 font-red font-weight-bold">
                    Erreur lors de l'inscription, merci de ré-essayer.
                  </span>
                </>
              )}
            </div>
          </>
        </form>
      </div>

      <div className="man-with-cup-and-suitcase"></div>

      <div className="man-with-big-pencil"></div>

      <div className="little-tree"></div>

    </>
  );
};

export default SignUpFormV2;
