import React, { useState } from "react";
import SetupTitleTemplate from "./SetupTitleTemplate";
import { useAtom } from "jotai";
import { newCampaignSetupStatusAtom } from "../../../../atoms/CampaignAtom";

const SetupAcceptance = () => {
  const [displaySetup, setDisplaySetup] = useState(false);
  const [newCampaignSetupStatus, setNewCampaignSetupStatus] = useAtom(
    newCampaignSetupStatusAtom
  );

  const handleAcceptanceInput = (e) => {
    setNewCampaignSetupStatus({
      ...newCampaignSetupStatus,
      acceptance: e.target.checked,
    });
  };

  return (
    <>
      <div className="item-setup-container">
        <SetupTitleTemplate
          setupTitle="AUTORISATION"
          displaySetup={displaySetup}
          setDisplaySetup={setDisplaySetup}
        />

        <div
          id={displaySetup && "acceptance-setup-processing"}
          className={!displaySetup ? "display-none" : "translation-vertical"}
        >
          {" "}
          <div className="acceptance-setup">
            <div className="padding-right-2vw">
              <input
                // required
                type="checkbox"
                name="acceptance"
                className="margin-right-1vw cursor-pointer"
                onInput={(e) => handleAcceptanceInput(e)}
              />
            </div>
            <div>
              J'autorise{" "}
              <span className="font-family-designosaur">
                Alliance Cyber Technologies
              </span>{" "}
              à réaliser cette campagne de faux-phishing à des fins de
              sensibilisation des employés de ma société quant aux bonnes
              pratiques de cybersécurité. En aucun cas{" "}
              <span className="font-family-designosaur">
                Alliance Cyber Technologies
              </span>{" "}
              ne pourra être tenue pour responsable d'erreurs ou de
              malveillances liées à l'utilisation de son outil .
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupAcceptance;
