import React from "react";

const Cgu = ({ setDisplayCGU }) => {
  return (
    <>
      <div id="cgu-modal-container">
        <div id="cgu-container">
          <h5 className="margin-top-1vh margin-bottom-1vh">
            Dernière mise à jour : 16/10/2023
          </h5>
          <h2 className="margin-top-5vh margin-bottom-5vh">
            Conditions d'utilisation
          </h2>
          <p>
            Les informations recueillies dans ce formulaire sont enregistrées
            dans un fichier informatisé par Alliance Cyber Technologies, société
            basée à Lille, et stockés sur des serveurs situés en Europe. La base
            légale du traitement est basée sur une relation pré-contractuelle.
            Les informations demandées dans le formulaire doivent
            obligatoirement être fournies. Dans le cas contraire, il vous sera
            impossible de poursuivre votre demande (inscription, campagne
            faux-phishing, etc.) . Les données collectées seront communiquées
            aux seuls destinataires suivants : Alliance Cyber Technologies. Vous
            pouvez demander à y accéder, les rectifier, demander leur effacement
            ou exercer votre droit à la limitation du traitement de vos données.
            Consultez le site cnil.fr pour plus d’informations sur vos droits.
            Pour exercer ces droits ou pour toute question sur le traitement de
            vos données dans ce dispositif, vous pouvez contacter (le cas
            échéant, notre délégué à la protection des données ou le service
            chargé de l’exercice de ces droits) : Alliance Cyber Technologies,
            Email: contact[@]alliancecybertech.com.
          </p>

          {/* <h2 className="margin-top-10vh margin-bottom-5vh">
            Politique de Cookies
          </h2>

          <p>
            Cette Politique de Cookies explique comment nous utilisons les
            cookies et autres technologies similaires sur notre site internet{" "}
            <span className="font-weight-bold">
              {process.env.REACT_APP_FRONTEND_URL}
            </span>{" "}
            (le "Site") exploité par{" "}
            <span className="font-weight-bold">
              Alliance Cyber Technologies
            </span>{" "}
            (ci-après dénommé "nous", "notre", ou "nos").
          </p>
          <h4 className="margin-top-5vh">Qu'est-ce qu'un cookie ?</h4>
          <p>
            Un cookie est un petit fichier de données stocké sur l'appareil de
            l'utilisateur lorsqu'il visite un site internet. Les cookies sont
            largement utilisés pour garantir le bon fonctionnement d'un site,
            pour collecter des informations sur la manière dont les visiteurs
            utilisent le site, et pour personnaliser l'expérience de
            l'utilisateur.
          </p>
          <h4 className="margin-top-5vh">
            Comment utilisons-nous les cookies ?
          </h4>
          <p>
            Nous utilisons des cookies sur notre Site pour les finalités
            suivantes :
          </p>
          <ol className="margin-left-3vw margin-right-3vw margin-top-5vh">
            <li>
              <p>
                Fonctionnalité essentielle : nous utilisons des cookies
                essentiels pour vous permettre de naviguer sur le Site et
                d'utiliser ses fonctionnalités de base. Ces cookies sont
                nécessaires pour que le Site fonctionne correctement.
              </p>
            </li>
            <li>
              <p>
                Analyse et performance : nous utilisons des cookies pour
                collecter des informations sur la manière dont les visiteurs
                utilisent le Site. Ces données nous aident à améliorer le Site
                et à comprendre comment il est utilisé.
              </p>
            </li>
            <li>
              <p>
                Stockage local (Local Storage) : dans certains cas, nous pouvons
                stocker des informations de formulaire dans le local storage de
                votre navigateur pour faciliter la saisie de données et procéder
                à l'exécution de fonctions essentielles (paramétrages des
                campagnes de faux-phishing en particulier).
              </p>
            </li>
          </ol>
          <h4 className="margin-top-5vh">Vos Options</h4>
          <p>
            Vous avez le droit de choisir d'accepter ou de refuser les cookies
            en configurant les paramètres de votre navigateur. La plupart des
            navigateurs acceptent automatiquement les cookies, mais vous pouvez
            généralement modifier les paramètres de votre navigateur pour
            refuser les cookies si vous le souhaitez. Notez que le refus des
            cookies peut affecter votre expérience de navigation sur notre Site.
          </p>
          <h4 className="margin-top-5vh">Consentement</h4>
          <p>
            En utilisant notre Site, vous consentez à notre utilisation de
            cookies conformément à cette Politique de Cookies. Si vous ne
            consentez pas à l'utilisation de cookies, veuillez configurer les
            paramètres de votre navigateur pour refuser les cookies ou ne pas
            utiliser notre Site.
          </p>
          <h4 className="margin-top-5vh">Contact</h4>
          <p>
            Si vous avez des questions concernant notre Politique de Cookies,
            veuillez nous contacter à contact[@]alliancecybertech.com.
          </p>

          <h4 className="margin-top-5vh">
            Modifications de la Politique de Cookies
          </h4>
          <p>
            Nous nous réservons le droit de mettre à jour cette Politique de
            Cookies à tout moment. Toute modification de cette politique sera
            publiée sur cette page.
          </p> */}
          <div className="margin-top-5vh">
            <button
              onClick={() => setDisplayCGU(false)}
              className="blue-button"
            >
              FERMER
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cgu;
