import React, { useEffect, useState } from "react";
import { cleanInput } from "../../../../utils/functions";
import { signIn } from "../../../../utils/functions";
import { useSetAtom } from "jotai/react";
import { useNavigate } from "react-router-dom";
import { authUserAtom } from "../../../../atoms/AuthUserAtom";
import ResetInstructionsFormV2 from "../../ResetPasswordV2/ResetInstructionsFormV2";
import { ColorRing } from "react-loader-spinner";
import MainViewHeader from "../../../../components/MainViewTitle/MainViewTitle";
import Announcements from "../../../../components/Announcement/Announcements";

const SignInFormV2 = ({ whichFormToDisplay }) => {
  const redirect = useNavigate();
  const setAuthUserData = useSetAtom(authUserAtom);
  const [authenticationStatus, setAuthenticationStatus] = useState(false);
  const [displayResetInstructionsForm, setDisplayResetInstructionsForm] =
    useState(false);
  const [isSigninLoading, setIsSigninLoding] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const login = cleanInput(e.target.email.value);
    const password = cleanInput(e.target.password.value);

    signIn(
      login,
      password,
      setAuthUserData,
      setAuthenticationStatus,
      redirect,
      setIsSigninLoding
    );
  };

  return (
    <>
      <MainViewHeader
        iconToDisplay={"IoMdLogIn"}
        textToDisplay={"SE CONNECTER"}
      />

      <Announcements htmlCode={"Accédez à votre compte"} />

      <div className="signin-container">
        <div className="div-center width-fit-content text-center margin-top-1vh">
          <span
            className="font-size-075 cursor-pointer font-weight-bold"
            onClick={() => whichFormToDisplay("signup")}
          >
            Pas de compte ? Inscrivez-vous.
          </span>
        </div>

        <form onSubmit={(e) => handleSubmit(e)} className="signin-form">
          <div className="input-container-signinup margin-bottom-2vh">
            <input
              required
              maxLength="50"
              type="email"
              name="email"
              placeholder="Identifiant (email)"
              className="input-signinup"
            />
          </div>
          <div className="input-container-signinup margin-bottom-2vh">
            <input
              required
              maxLength="50"
              type="password"
              name="password"
              placeholder="Mot de passe"
              className="input-signinup"
            />
          </div>

          <div>
            {authenticationStatus && (
              <span className="font-size-075 font-red font-weight-bold">
                Identifiant ou mot de passe érroné
              </span>
            )}
          </div>

          <div className="button-container margin-top-2vh">
            {isSigninLoading ? (
              <>
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#232233",
                    "#2b2c40",
                    "#232747",
                    "#5D87FF",
                    "#539BFF",
                  ]}
                />
              </>
            ) : (
              <>
                <button type="submit" className="blue-button">
                  OK
                </button>
              </>
            )}
          </div>
        </form>

        <div className="margin-top-2vh">
          <span
            className="font-size-075 cursor-pointer font-weight-bold"
            onClick={() => setDisplayResetInstructionsForm(true)}
          >
            Mot de passe perdu ?
          </span>
        </div>

        {displayResetInstructionsForm && (
          <>
            <ResetInstructionsFormV2
              setDisplayResetInstructionsForm={setDisplayResetInstructionsForm}
            />
          </>
        )}
      </div>

      <div className="man-rising-cup"></div>

      <div className="two-men-climbing"></div>
    </>
  );
};

export default SignInFormV2;
