import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { newCampaignElementsAtom } from "../../../atoms/CampaignAtom";
import { authUserAtom } from "../../../atoms/AuthUserAtom";
import Cookies from "js-cookie";
import { ColorRing } from "react-loader-spinner";
import { previousPageAtom } from "../../../atoms/PreviousPageAtom";
import { paymentFormAccessAtom } from "../../../atoms/PaymentFormAccessAtom";
import MainViewTitle from "../../../components/MainViewTitle/MainViewTitle";
import Announcements from "../../../components/Announcement/Announcements";

const PaymentSuccessfullV2 = () => {
  const redirect = useNavigate();
  const newCampaignElements = useAtomValue(newCampaignElementsAtom);
  const authUser = useAtomValue(authUserAtom);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const previousPage = useAtomValue(previousPageAtom);
  const setPaymentFormAccess = useSetAtom(paymentFormAccessAtom);
  let landingPage = "";

  var prefix;

  if (process.env.REACT_APP_FRONTEND_URL === "http://localhost:3001/") {
    prefix = "LOCAL_";
  }
  if (
    process.env.REACT_APP_FRONTEND_URL ===
    "https://phishandcheap-dev.vercel.app/"
  ) {
    prefix = "DEV_";
  }
  if (
    process.env.REACT_APP_FRONTEND_URL === "https://phishandcheap.vercel.app/"
  ) {
    prefix = "PROD_";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    // Here, we avoid accessing this page by any other page than campaign-control, otherwise a backend request will be sent one more time.
    previousPage &&
      previousPage !== "/campaign-control" &&
      redirect("/new-campaign");

    // Here we close the payement form
    setPaymentFormAccess("closed");
  }, [previousPage, redirect, newCampaignElements, setPaymentFormAccess]);

  const campaignProcessing = () => {
    // console.log("newCampaignElements :\n", newCampaignElements);
    setIsButtonClicked(true);

    // Create a users group in Gophish DB
    createAUsersGroupInGophish();
  };

  const createAUsersGroupInGophish = () => {
    // console.log(
    //   "createAUsersGroupInGophish newCampaignElements :\n",
    //   newCampaignElements
    // );

    fetch(
      `${process.env.REACT_APP_GOPHISH_API}groups/?api_key=${process.env.REACT_APP_GOPHISH_API_KEY}`,
      {
        method: "POST",
        body: JSON.stringify({
          name: prefix + newCampaignElements.usersGroupName,
          targets: newCampaignElements.emailsList,
        }),
      }
    )
      .then((response) => {
        // console.log("createAUsersGroupInGophish Response :\n", response);

        // Depending on the browser used, the response could be "Created" (for Firefox) or "" (for Chrome and Opera)
        if (response.statusText !== "Created" && response.statusText !== "") {
          alert(
            "Erreur lors du transfert de la liste d'emails.\nContacter notre assistance si le problème persiste."
          );
        }

        return response.json();
      })
      .then(() => {
        createCampaignInGophish();
      });
    // .catch(error => console.error("createAUsersGroupInGophish -> ERROR\n", error.message))
  };

  const createCampaignInGophish = () => {
    if (newCampaignElements.templateName === "Boîte mail pleine") {
      landingPage = "[PIÈGE NON RÉVÉLÉ] - Boîte mail espace libéré";
    }

    if (
      newCampaignElements.templateName ===
      "Réinitialisation du mot de passe sous 7 jours"
    ) {
      landingPage = "[PIÈGE NON RÉVÉLÉ] - Réessayez plus tard";
    }

    if (newCampaignElements.templateName === "Coupures réseau prochaine") {
      landingPage = "[PIÈGE NON RÉVÉLÉ] - Coupures réseau planning";
    }

    fetch(
      `${process.env.REACT_APP_GOPHISH_API}campaigns/?api_key=${process.env.REACT_APP_GOPHISH_API_KEY}`,
      {
        method: "POST",
        body: JSON.stringify({
          name: prefix + newCampaignElements.name,
          template: { name: `${newCampaignElements.templateName}` },
          url: process.env.REACT_APP_GOPHISH_SERVER,
          // page: { name: "[PIÈGE NON RÉVÉLÉ] - Réessayez plus tard" },
          page: { name: landingPage },
          smtp: { name: "Sendgrid (administrateur@notification-info.fr)" },
          launch_date: newCampaignElements.launchDate,
          send_by_date: null,
          groups: [{ name: prefix + newCampaignElements.usersGroupName }],
        }),
      }
    )
      .then((response) => {
        // Depending on the browser used, the response could be "Created" (for Firefox) or "" (for Chrome and Opera)
        if (response.statusText !== "Created" && response.statusText !== "") {
          alert(
            "Erreur lors de la création de la campagne.\nContacter notre assistance si le problème persiste."
          );
        } else {
        }
        return response.json();
      })
      .then((data) => {
        // console.log("createCampaignInGophish DATA :\n", data);
        // Create an entry in backend right after creating it in Gophish
        createCampaignInBackend(data.id);
      });
    // .catch(error => console.error(error.message))
  };

  const createCampaignInBackend = (gophishId) => {
    const campaignData = {
      user_id: authUser.user.id,
      gophish_id: gophishId,
      name: newCampaignElements.name,
      launch_date: newCampaignElements.launchDate,
      send_by: newCampaignElements.sendBy,
      template: newCampaignElements.templateName,
      consent: true,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}campaigns`, {
      method: "POST",
      headers: {
        Authorization: Cookies.get("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaign: campaignData,
      }),
    })
      .then((response) => {
        if (response.status !== 201) {
          alert("Erreur lors de l'historisation de la campagne.");
          Cookies.remove("token");
          window.location.replace(
            process.env.REACT_APP_FRONTEND_URL + "signinup"
          );
        }
        return response.json();
      })
      .then(() => {
        redirect("/your-campaigns");
      });
    // .catch(error => console.error("createCampaignInBackend -> ERROR", error.message))
  };

  return (
    <div>
      <MainViewTitle
        iconToDisplay={"BsCreditCard2Back"}
        textToDisplay={"PAIEMENT RÉUSSI"}
      />

      <Announcements htmlCode={"Merci pour votre paiement."} />

      <div className="margin-top-10vh text-center">
        {isButtonClicked ? (
          <>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#001db9", "#495bc0", "#98a6f5", "#d1e7ff", "#e7f3ff"]}
            />
          </>
        ) : (
          <>
            Pour activer la programmation de votre campagne cliquez sur
            <div className="margin-top-5vh">
              <button
                className="green-button"
                onClick={() => campaignProcessing()}
              >
                SUIVANT
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccessfullV2;
