import React from "react";

const CgvV2 = ({ setDisplayCGV }) => {
  return (
    <>
      <div id="cgv-modal-container">
        <div id="cgv-container">
          <h2 className="margin-top-2vh">Conditions générales de vente</h2>
          <div id="disclaimer-container">
            <p className="text-center">
              Les présentes conditions de vente régissent les relations
              contractuelles entre{" "}
              <a
                href="https://alliancecybertech.eu/"
                target="_blank"
                rel="noreferrer"
              >
                Alliance Cyber Technologies
              </a>{" "}
              (ci-après dénommé "le vendeur") et le client (ci-après dénommé "le
              client") lors de l'achat de services sur le site internet{" "}
              <em>Phish & Cheap</em> (ci-après dénommé "le site"), accessible
              depuis l'adresse {process.env.REACT_APP_FRONTEND_URL} .
            </p>
          </div>

          <div id="all-rules-container">
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">Objet</span>
              </div>
              <div>
                Les présentes conditions ont pour objet de définir les droits et
                obligations des parties dans le cadre de la vente de services
                proposés par le vendeur sur le site.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">Produits et services</span>
              </div>
              <div>
                Le vendeur propose un service de campagnes de faux-phishing
                automatisées dont le contenu est décrit sur ce site. Les
                caractéristiques essentielles des services, leur prix et leur
                disponibilité sont indiqués sur les pages correspondantes.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">Commandes</span>
              </div>
              <div>
                Le client peut passer commande sur le site en suivant le
                processus de commande prévu. Le vendeur se réserve le droit de
                refuser toute commande en cas de problème de paiement,
                d'indisponibilité des services ou de non-respect des présentes
                conditions de vente.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">Prix et paiement</span>
              </div>
              <div>
                Les prix des services sont indiqués en euros et sont toutes
                taxes comprises (TTC). Le paiement s'effectue en ligne au moment
                de la commande par les moyens de paiement proposés sur le site.
                Le vendeur se réserve le droit de modifier les prix à tout
                moment, mais les services seront facturés au tarif en vigueur au
                moment de la validation de la commande.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">Livraison</span>
              </div>
              <div>
                Étant donné que les services sont intangibles, la livraison se
                fait par voie électronique, par le biais d'un accès en ligne ou
                par l'envoi de fichiers électroniques. Le service est fourni de
                façon immédiate après paiement.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">Droit de rétractation</span>
              </div>
              <div>
                Conformément à la législation en vigueur, le client dispose d'un
                délai de rétractation de 14 jours à compter de la réception des
                services pour exercer son droit de rétractation. Cependant, ce
                droit ne peut être exercé pour les services fournis de manière
                immédiate et intégralement exécutés. A partir du moment où la
                campagne est programmée, le service est considéré comme étant
                consommé.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">
                  Propriété intellectuelle
                </span>
              </div>
              <div>
                Le vendeur conserve tous les droits de propriété intellectuelle
                sur les services vendus. Le client s'engage à ne pas utiliser,
                reproduire ou diffuser les services sans autorisation préalable
                du vendeur.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">Responsabilité</span>
              </div>
              <div>
                Le vendeur s'engage à fournir les services conformément aux
                normes de qualité en vigueur. Cependant, sa responsabilité ne
                saurait être engagée en cas de force majeure, de
                dysfonctionnement du réseau internet ou de tout autre événement
                échappant à son contrôle. En aucun cas la société Alliance Cyber
                Technologies ne peut etre tenue pour responsable d'un
                dysfonctionneùent de la campagne de faux-phishing, ni
                d'éventuels mécontenemts d'utilisateurs. L'intégralité de la
                campagne de faux-phishing est de la résponsabilité du client
                demandeur.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">
                  Protection des données personnelles
                </span>
              </div>
              <div>
                Le vendeur collecte et traite les données personnelles du client
                conformément à sa politique de confidentialité. Le client
                dispose d'un droit d'accès, de rectification et de suppression
                de ses données personnelles.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">Litiges</span>
              </div>
              <div>
                En cas de litige, les parties s'engagent à rechercher une
                solution amiable. À défaut d'accord, le litige sera soumis aux
                tribunaux compétents conformément à la législation en vigueur.
              </div>
            </div>
            <div className="single-rule-container">
              <div className="margin-bottom-1vh">
                <span className="font-weight-bold">
                  Modification des conditions
                </span>
              </div>
              <div>
                Le vendeur se réserve le droit de modifier les présentes
                conditions de vente à tout moment. Les conditions applicables
                sont celles en vigueur au moment de la commande.
              </div>
            </div>
            <div className="single-rule-container">
              En passant commande sur le site, le  className="margin-bottom-1vh"client reconnaît avoir pris
              connaissance des présentes conditions de vente et les accepte sans
              réserve.
            </div>
          </div>

          <div className="div-center width-fit-content text-center margin-top-5vh">
            <div onClick={() => setDisplayCGV(false)} className="blue-button">
              FERMER
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CgvV2;
