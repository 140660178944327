import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Catchphrase from "./components/Navbar/Catchphrase";
import Cookies from "js-cookie";
import { useAtomValue } from "jotai";
import { authUserAtom } from "../../atoms/AuthUserAtom";
import CookiesBanner from "../CookiesPolicy/CookiesBanner";

const Main = () => {
  const authUserData = useAtomValue(authUserAtom);
  const [displayCookiesBanner, setDisplayCookiesBanner] = useState(true);

  useEffect(() => {
    Cookies.get(process.env.REACT_APP_COOKIES_POLICY_VALIDATION) &&
      setDisplayCookiesBanner(false);
  }, [setDisplayCookiesBanner]);

  return (
    <>
      <main>
        <div className="main-container">
          <nav className="navbar-container">
            {authUserData && Cookies.get("token") ? (
              <div className="menu-container">
                <Navbar />
              </div>
            ) : (
              <Catchphrase />
            )}{" "}
          </nav>

          <div className="outlet-container">
            <Outlet />
          </div>
        </div>
      </main>

      {displayCookiesBanner && (
        <CookiesBanner setDisplayCookiesBanner={setDisplayCookiesBanner} />
      )}
    </>
  );
};

export default Main;
