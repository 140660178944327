import React, { useEffect } from "react";
import { ImStatsBars } from "react-icons/im";
import { useNavigate } from "react-router-dom";

const YourCampaignsButtons = ({
  setMenuSelection,
  isYourCampaignsSelected,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    setMenuSelection("your-campaigns");
  }, [setMenuSelection]);

  return (
    <>
      <div
        className={
          isYourCampaignsSelected
            ? "menu-button-selected"
            : "menu-button"
        }
        onClick={() => navigate("/your-campaigns")}
      >
        <div className="menu-button-icon">
          <ImStatsBars size="1.5em" color="white" />
        </div>

        <div className="menu-button-text">VOS CAMPAGNES</div>
      </div>
    </>
  );
};

export default YourCampaignsButtons;
