import React from "react";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { campaignPriceAtom } from "../../../../atoms/CampaignAtom";
import { useLocation } from "react-router-dom";

const Catchphrase = () => {
  const CampaignPrice = useAtomValue(campaignPriceAtom);
  const navigate = useNavigate();
  const currentURL = useLocation();

  const handleCTA = () => {
    navigate("/signinup");
  };

  return (
    <>
      <div className="catchphrase-element">
        <div className="catchphrase-container">
          <h4 className="catchphrase">
            Vos campagnes anti-phishing
            <br />
            pour
            <span className="font-family-designosaur font-green margin-left-1vw">
              {CampaignPrice && CampaignPrice}€ TTC
            </span>
          </h4>
        </div>

        <div className="cta-container">
          {currentURL.pathname === "/" ? (
            <>
              {" "}
              <button className="cta-button" onClick={() => handleCTA()}>
                <h2>GO</h2>
              </button>
            </>
          ) : (
            <>
              {" "}
              <button className="cta-button faded">
                <h2>GO</h2>
              </button>
            </>
          )}

          {/* <div className="cta-image">
          <img
            src={iconHameconnage}
            alt="icone d'hammeçonage"
            width="25vw"
            height="auto"
          />
        </div> */}
        </div>
      </div>
    </>
  );
};

export default Catchphrase;
