import React, { useEffect, useState } from "react";
import {
  BsCreditCard2Back,
  BsFillJournalBookmarkFill,
  BsLockFill,
} from "react-icons/bs";
import {
  FaLightbulb,
  FaPlusCircle,
  FaUserCircle,
  FaUsers,
} from "react-icons/fa";
import { IoMdLogIn } from "react-icons/io";
import { ImStatsBars } from "react-icons/im";

const MainViewTitle = ({ iconToDisplay, textToDisplay }) => {
  const [icon] = useState(iconToDisplay);

  useEffect(() => {});

  return (
    <>
      <div className="main-view-title">
        <div className="main-view-title-icon">
          {icon === "IoMdLogIn" && <IoMdLogIn />}
          {icon === "FaUsers" && <FaUsers />}
          {icon === "BsFillJournalBookmarkFill" && (
            <BsFillJournalBookmarkFill />
          )}
          {icon === "BsCreditCard2Back" && <BsCreditCard2Back />}
          {icon === "ImStatsBars" && <ImStatsBars />}
          {icon === "FaLightbulb" && <FaLightbulb color="yellow" className="blinking" />}
          {icon === "FaUserCircle" && <FaUserCircle />}
          {icon === "FaPlusCircle" && <FaPlusCircle />}
          {icon === "BsLockFill" && <BsLockFill />}
          {/* {icon === "FaUsers" && <FaUsers />} */}
        </div>

        <div className="main-view-title-text">
          <h2 className="font-family-designosaur">{textToDisplay}</h2>
        </div>
      </div>
    </>
  );
};

export default MainViewTitle;
