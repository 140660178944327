import React, { useEffect } from "react";
import NewCampaignButton from "./NewCampaignButton";
import YourCampaignsButtons from "./YourCampaignsButtons";
import UserAccountButton from "./UserAccountButton";
import { useAtom } from "jotai";
import SystemTestButton from "./SystemTestButton";
import {
  isNewCampaignSelectedAtom,
  isSystemTestSelectedAtom,
  isUserAccountSelectedAtom,
  isYourCampaignsSelectedAtom,
  menuSelectionAtom,
} from "../../../../atoms/MenuSelectionAtom";
import { useLocation } from "react-router-dom";

const Nav = () => {
  const [menuSelection, setMenuSelection] = useAtom(menuSelectionAtom);
  const [isUserAccountSelected, setIsUserAccountSelected] = useAtom(
    isUserAccountSelectedAtom
  );
  const [isNewCampaignSelected, setIsNewCampaignSelected] = useAtom(
    isNewCampaignSelectedAtom
  );
  const [isYourCampaignsSelected, setIsYourCampaignsSelected] = useAtom(
    isYourCampaignsSelectedAtom
  );
  const [isSystemTestSelected, setIsSystemTestSelected] = useAtom(
    isSystemTestSelectedAtom
  );
  const currentLocation = useLocation();

  useEffect(() => {
    const getCurrentURL = () => {
      return currentLocation.pathname;
    };

    const updateMenuSelection = (currentURL) => {
      // currentURL === "/" && resetToolsSelection();

      (currentURL === "/user-infos" || currentURL === "/user-infos-edition") 
        ? setIsUserAccountSelected(true)
        : setIsUserAccountSelected(false);

      currentURL === "/new-campaign"
        ? setIsNewCampaignSelected(true)
        : setIsNewCampaignSelected(false);

      currentURL === "/your-campaigns"
        ? setIsYourCampaignsSelected(true)
        : setIsYourCampaignsSelected(false);

      currentURL === "/system-test"
        ? setIsSystemTestSelected(true)
        : setIsSystemTestSelected(false);
    };

    const currentURL = getCurrentURL();
    updateMenuSelection(currentURL);
  }, [
    menuSelection,
    setIsUserAccountSelected,
    setIsNewCampaignSelected,
    setIsYourCampaignsSelected,
    setIsSystemTestSelected,
    currentLocation,
  ]);

  return (
    <>
      <UserAccountButton
        setMenuSelection={setMenuSelection}
        isUserAccountSelected={isUserAccountSelected}
      />
      <NewCampaignButton
        setMenuSelection={setMenuSelection}
        isNewCampaignSelected={isNewCampaignSelected}
      />
      <YourCampaignsButtons
        setMenuSelection={setMenuSelection}
        isYourCampaignsSelected={isYourCampaignsSelected}
      />
      <SystemTestButton
        setMenuSelection={setMenuSelection}
        isSystemTestSelected={isSystemTestSelected}
      />
    </>
  );
};

export default Nav;
