import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { authUserAtom } from "../../../atoms/AuthUserAtom";
import { useNavigate } from "react-router-dom";
import MainViewTitle from "../../../components/MainViewTitle/MainViewTitle";
import { formatPhoneNumber } from "../../../utils/functions";
import Announcements from "../../../components/Announcement/Announcements";

const UserInfosV2 = () => {
  const authUserData = useAtomValue(authUserAtom);
  const navigate = useNavigate();
  const userData = useAtomValue(authUserAtom);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainViewTitle
        iconToDisplay={"FaUserCircle"}
        textToDisplay={"VOS INFORMATIONS"}
      />

      {userData &&
      (userData.user.organization === "" ||
        userData.user.phone === "" ||
        userData.user.contact === "" ||
        userData.user.address === "" ||
        userData.user.siret === "") ? (
        <>
          <Announcements
            htmlCode={`Merci de compléter les informations suivantes pour pouvoir créer des campagnes.`}
          />
        </>
      ) : (
        <>
          <Announcements htmlCode={`Vous pouvez créer des campagnes.`} />
        </>
      )}

      <div className="user-infos-container">
        <div className="info-container">
          <div className="info-title">Société :&nbsp;</div>
          <div className="info-content">
            {authUserData && authUserData.user.organization}
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">Personne à contacter :&nbsp;</div>
          <div className="info-content">
            {authUserData && authUserData.user.contact}
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">Téléphone :&nbsp;</div>
          <div className="info-content">
            {authUserData && formatPhoneNumber(authUserData.user.phone)}
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">Adresse :&nbsp;</div>
          <div className="info-content">
            {authUserData && authUserData.user.address}
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">SIRET :&nbsp;</div>
          <div className="info-content">
            {authUserData && authUserData.user.siret}
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">Email :&nbsp;</div>
          <div className="info-content">
            {authUserData && authUserData.user.email}
          </div>
        </div>

        <div className="submit-container">
          <div className="button-container div-center margin-top-5vh">
            <button
              onClick={() => navigate("/user-infos-edition")}
              className="blue-button"
            >
              EDITER
            </button>
          </div>

          <div className="button-container div-center margin-top-5vh">
            {userData &&
            (userData.user.organization === "" ||
              userData.user.phone === "" ||
              userData.user.contact === "" ||
              userData.user.address === "" ||
              userData.user.siret === "") ? (
              <>
                <button
                  onMouseEnter={() =>
                    alert(
                      "Les informations doivent être complètes pour pouvoir créer une nouvelle campagne."
                    )
                  }
                  className="green-button faded"
                >
                  Nouvelle campagne
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => navigate("/new-campaign")}
                  className="green-button"
                >
                  Nouvelle campagne
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="woman-phoning"></div>
      <div className="woman-with-documents"></div>
    </>
  );
};

export default UserInfosV2;
