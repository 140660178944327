import { passwordStrength } from "check-password-strength";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cleanInput, resetPassword } from "../../../utils/functions";
import PasswordInstructions from "../SignInUpV2/components/StrongPasswordInstructions";
import MainViewTitle from "../../../components/MainViewTitle/MainViewTitle";
import Announcements from "../../../components/Announcement/Announcements";

const ResetPasswordFormV2 = () => {
  const token = useParams().token;
  const redirect = useNavigate();
  const [displayPasswordInstructions, setDisplayPasswordInstructions] =
    useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const password = cleanInput(e.target.password.value);
    const passwordConfirmation = cleanInput(
      e.target.passwordconfirmation.value
    );

    if (passwordStrength(password).value !== "Strong") {
      alert("Mot de passe trop faible.");
    } else if (password !== passwordConfirmation) {
      alert("Les mots de passe saisis ne correspondent pas.");
    } else {
      resetPassword(token, password, passwordConfirmation);
      redirect("/signinup");
    }
  };

  return (
    <>
      <div>
        <MainViewTitle
          iconToDisplay={"BsLockFill"}
          textToDisplay={"RÉINITALISATION"}
        />

        <Announcements htmlCode={"Entrez votre nouveau mot de passe"} />

        <div className="reset-password-form">
          <form onSubmit={(e) => handleSubmit(e)} className="">
            <div className="input-container-signinup margin-bottom-2vh">
              <input
                required
                minLength="12"
                maxLength="50"
                type="password"
                name="password"
                placeholder="Nouveau mot de passe"
                className="input-signinup"
              />
            </div>

            <div className="input-container-signinup margin-bottom-2vh">
              <input
                required
                minLength="12"
                maxLength="50"
                type="password"
                name="passwordconfirmation"
                placeholder="Confirmer mot de passe"
                className="input-signinup"
              />
            </div>

            <div className="margin-top-5vh">
              <PasswordInstructions
                displayPasswordInstructions={displayPasswordInstructions}
                setDisplayPasswordInstructions={setDisplayPasswordInstructions}
              />
            </div>

            <div className="button-container margin-top-5vh">
              <button type="submit" className="blue-button">
                OK
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordFormV2;
