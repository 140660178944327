import React, { useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const UserAccountButton = ({ setMenuSelection, isUserAccountSelected }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setMenuSelection("user-infos");
  }, [setMenuSelection]);

  return (
    <>
      <div
        className={
          isUserAccountSelected
            ? "menu-button-selected"
            : "menu-button"
        }
        onClick={() => navigate("/user-infos")}
      >
        <div className="menu-button-icon">
          <FaUserCircle size="1.5em" color="white" />
        </div>

        <div className="menu-button-text">VOS INFORMATIONS</div>
      </div>
    </>
  );
};

export default UserAccountButton;
