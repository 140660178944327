import { useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { senderProfileAtom } from "../../../atoms/SenderProfileAtom";
import SystemTestForm from "./components/SystemTestForm";
import MainViewTitle from "../../../components/MainViewTitle/MainViewTitle";
import Announcements from "../../../components/Announcement/Announcements";

const SystemTest = () => {
  const senderProfile = useAtomValue(senderProfileAtom);
  const [isMySystemReady, setIsMySystemReady] = useState(false);
  const [displaySystemTestForm, setDisplaySystemTestForm] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <MainViewTitle
          iconToDisplay={"FaLightbulb"}
          textToDisplay={"TESTEZ VOTRE SYSTÈME"}
        />

        <Announcements
          htmlCode={
            "Contrôlez vos règles de filtrage de messagerie."
          }
        />

        <div className="system-test-disclaimer">
          Les emails envoyés lors des campagnes de faux-phishing automatisées ne
          doivent pas être bloqués par votre <u>filtrage de messagerie</u> ou{" "}
          <u>anti-spam</u>. Ainsi, merci de vous assurer que notre adresse
          d'expédition soit autorisée à envoyer vers votre système :
          <div className="margin-top-2vh text-center font-dark-blue1">
            <strong>{senderProfile}</strong>
          </div>
        </div>

        <div className="system-test-buttons">
          <div className="blue-button" onClick={() => setIsMySystemReady(true)}>
            Autorisation vérifiée !
          </div>
          {isMySystemReady ? (
            <>
              <div
                className="green-button"
                onClick={() => setDisplaySystemTestForm(true)}
              >
                Envoyer un test
              </div>
            </>
          ) : (
            <>
              <div className="green-button faded">Envoyer un test</div>
            </>
          )}
        </div>
      </div>

      {displaySystemTestForm && (
        <SystemTestForm setDisplaySystemTestForm={setDisplaySystemTestForm} />
      )}

      <div className="woman-sitting-on-desk"></div>

      <div className="man-touching-screen"></div>

    </>
  );
};

export default SystemTest;
