import React from "react";
import { BsDot } from "react-icons/bs";
import { FaHandPointRight } from "react-icons/fa";

const StrongPasswordInstructions = ({
  displayPasswordInstructions,
  setDisplayPasswordInstructions,
}) => {
  return (
    <>
      <div
        className="password-instructions"
        onClick={() =>
          setDisplayPasswordInstructions(!displayPasswordInstructions)
        }
      >
        <FaHandPointRight /> comment choisir un mot de passe fort ?
        {displayPasswordInstructions && (
          <>
            <ul>
              <li className="margin-top-1vh margin-left-1vw">
                <BsDot /> 12 caractères minimum
              </li>
              <li className="margin-left-1vw">
                <BsDot /> des minuscules
              </li>
              <li className="margin-left-1vw">
                <BsDot /> des majuscules
              </li>
              <li className="margin-left-1vw">
                <BsDot /> des chiffres
              </li>
              <li className="margin-left-1vw margin-bottom-1vh">
                <BsDot /> des caractères spéciaux
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  );
};

export default StrongPasswordInstructions;
