import { useAtomValue } from "jotai";
import React from "react";
import { newCampaignSetupStatusAtom } from "../../../../atoms/CampaignAtom";

const MissingInputs = () => {
  const newCampaignSetupStatus = useAtomValue(newCampaignSetupStatusAtom);

  return (
    <>
      <div className="missing-inputs-modal translation-horizontal">
          {!newCampaignSetupStatus.emailsList && (
            <>
              <div className="text-left margin-bottom-2vh">
              <span className="blinking-2s font-red font-weight-bold margin-right-1vw">
                INCOMPLET
              </span>{" "}
              <span className="font-dark-blue1">
                Étape 2 : la liste des emails
              </span>
              </div>
            </>
          )}
          {!newCampaignSetupStatus.name && (
            <>
              <div className="text-left margin-bottom-2vh">
              <span className="blinking-2s font-red font-weight-bold margin-right-1vw">
                INCOMPLET
              </span>{" "}
              <span className="font-dark-blue1">
                Étape 3 : le nom de la campagne
              </span>
              </div>
            </>
          )}
          {!newCampaignSetupStatus.launchDate && (
            <>
              <div className="text-left margin-bottom-2vh">
              <span className="blinking-2s font-red font-weight-bold margin-right-1vw">
                INCOMPLET
              </span>{" "}
              <span className="font-dark-blue1">
                Étape 3 : la date de départ
              </span>
              </div>
            </>
          )}
          {!newCampaignSetupStatus.launchHour && (
            <>
              <div className="text-left margin-bottom-2vh">
              <span className="blinking-2s font-red font-weight-bold margin-right-1vw">
                INCOMPLET
              </span>{" "}
              <span className="font-dark-blue1">
                Étape 3 : l'heure de départ
              </span>
              </div>
            </>
          )}
          {!newCampaignSetupStatus.acceptance && (
            <>
              <div className="text-left margin-bottom-2vh">
              <span className="blinking-2s font-red font-weight-bold margin-right-1vw">
                INCOMPLET
              </span>{" "}
              <span className="font-dark-blue1">
                Étape 4 : autorisation
              </span>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default MissingInputs;
