import React, { useState } from "react";
import mailboxScreen from "../../../../images/fakePhishingTemplates/templateMailbox.png";
import networkScreen from "../../../../images/fakePhishingTemplates/templateNetwork.png";
import passwordScreen from "../../../../images/fakePhishingTemplates/templatePassword.png";
import SetupTitleTemplate from "./SetupTitleTemplate";
import { useAtom } from "jotai/react";
import {
  newCampaignElementsAtom,
  newCampaignSetupStatusAtom,
} from "../../../../atoms/CampaignAtom";

const SetupPhishingTemplate = () => {
  const [newCampaignElements, setNewCampaignElements] = useAtom(
    newCampaignElementsAtom
  );
  const [newCampaignSetupStatus, setNewCampaignSetupStatus] = useAtom(
    newCampaignSetupStatusAtom
  );
  const [displaySetup, setDisplaySetup] = useState(false);

  const handleTemplateInput = (template) => {
    setNewCampaignElements({
      ...newCampaignElements,
      templateScreenshot: template,
    });

    setNewCampaignSetupStatus({
      ...newCampaignSetupStatus,
      template: true,
    });
  };

  return (
    <>
      <div className="item-setup-container">
        <SetupTitleTemplate
          setupTitle="CHOISISSEZ LE FAUX PHISHING"
          displaySetup={displaySetup}
          setDisplaySetup={setDisplaySetup}
        />

        <div
          id={displaySetup && "template-setup-processing"}
          className={!displaySetup ? "display-none" : "translation-vertical"}
        >
          <div id="template-radio-buttons">
            <label className="cursor-pointer">
              <input
                // required
                className="margin-right-1vw"
                type="radio"
                name="template"
                value="Boîte mail pleine"
                checked={
                  newCampaignElements.templateScreenshot === mailboxScreen
                }
                onClick={() => handleTemplateInput(mailboxScreen)}
              />
              <span className="font-family-designosaur font-size-085">Boîte mail pleine</span>
            </label>
            <label className="cursor-pointer">
              <input
                // required
                className="margin-right-1vw"
                type="radio"
                name="template"
                value="Réinitialisation du mot de passe sous 7 jours"
                checked={
                  newCampaignElements.templateScreenshot === passwordScreen
                }
                onClick={() => handleTemplateInput(passwordScreen)}
              />
              <span className="font-family-designosaur font-size-085">
                Réinitialisation du mot de passe
              </span>
            </label>
            <label className="cursor-pointer">
              <input
                // required
                className="margin-right-1vw"
                type="radio"
                name="template"
                value="Coupures réseau prochaine"
                checked={
                  newCampaignElements.templateScreenshot === networkScreen
                }
                onClick={() => handleTemplateInput(networkScreen)}
              />
              <span className="font-family-designosaur font-size-085">
                Coupures réseau prochaine
              </span>
            </label>
          </div>

          <div id="template-screenshot-container">
            <img
              className="template-screenshot"
              src={newCampaignElements.templateScreenshot}
              alt="Choissez un modèle de faux piège"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupPhishingTemplate;
