import "../src/styles/stylesV2.scss";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import SharedLayoutV2 from "./pages/Version2/SharedLayoutV2/SharedLayoutV2";
import HomepageV2 from "./pages/Version2/HomepageV2/HomepageV2";
import SignInUpV2 from "./pages/Version2/SignInUpV2/SignInUpV2";
import ResetPasswordFormV2 from "./pages/Version2/ResetPasswordV2/ResetPasswordFormV2";
import UserInfosEditionV2 from "./pages/Version2/UserInfosV2/components/UserInfosEditionV2";
import NewCampaignV2 from "./pages/Version2/CampaignsV2/NewCampaignV2";
import CampaignSetUpControlV2 from "./pages/Version2/CampaignsV2/CampaignSetUpControlV2";
import YourCampaignsV2 from "./pages/Version2/CampaignsV2/YourCampaignsV2";
import PaymentV2 from "./pages/Version2/PayementV2/PaymentV2";
import PaymentSuccessfullV2 from "./pages/Version2/PayementV2/PaymentSuccessfullV2";
import Page404V2 from "./pages/Version2/Page404V2/Page404V2";
import ProtectedRoutes from "./pages/Version2/ProtectedRoutes/ProtectedRoutes";
import GophishTools from "./pages/GophishTools";
import SystemTest from "./pages/Version2/SystemTest/SystemTest";
import UserInfosV2 from "./pages/Version2/UserInfosV2/UserInfosV2";

export const App = () =>{
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SharedLayoutV2 />}>
          <Route path="/" element={<HomepageV2 />} />
          <Route path="/signinup" element={<SignInUpV2 />} />
          <Route
            path="/new_password/:token"
            element={<ResetPasswordFormV2 />}
          />
          <Route path="/payment" element={<PaymentV2 />} />
          <Route
            path="/payment/payment-successfull"
            element={<PaymentSuccessfullV2 />}
          />
          <Route path="/*" element={<Page404V2 />} />
          <Route path="/tools" element={<GophishTools />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/user-infos" element={<UserInfosV2 />} />
            <Route
              path="/user-infos-edition"
              element={<UserInfosEditionV2 />}
            />
            <Route path="/new-campaign" element={<NewCampaignV2 />} />
            <Route path="/your-campaigns" element={<YourCampaignsV2 />} />
            <Route path="/system-test" element={<SystemTest />} />

            <Route
              path="/campaign-control"
              element={<CampaignSetUpControlV2 />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
