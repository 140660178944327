
import React from 'react';
import { IoMdLogIn, IoMdLogOut } from "react-icons/io";
import { Link } from "react-router-dom";
import { signOut } from '../../../utils/functions';
import Cookies from 'js-cookie';
import { useAtomValue } from 'jotai';
import { authUserAtom } from '../../../atoms/AuthUserAtom';

const SigninupButton = () => {

    const authUserData = useAtomValue(authUserAtom);

    return (
        <>
        <div className="signinup-container">
          <div className="signinup-button">
            {authUserData && Cookies.get("token") ? (
              <>
                <IoMdLogOut
                  color="rgb(247, 106, 102)"
                  size="2.3em"
                  onClick={() => signOut()}
                />
              </>
            ) : (
              <>
                <Link to="/signinup">
                  <IoMdLogIn color="white" size="2.3em" />
                </Link>
              </>
            )}
          </div>
        </div>
        </>
    );
};

export default SigninupButton;