import React from "react";
import { Link } from "react-router-dom";
import logoACT from "../../images/logo2023-act.png";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-container">
          <div className="padding-top-0-5vh">
            © 2022-{new Date().getFullYear()} Tous droits réservés &nbsp;
          </div>

          <Link to="https://alliancecybertech.eu/" target="_blank">
            <div className="logo-part">
              <img
                src={logoACT}
                alt="logo de la société alliance cyber technologies"
                width="20vw"
                height="20vw"
              />
              <div className="padding-top-0-5vh">
                lliance Cyber Technologies
              </div>
            </div>
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
