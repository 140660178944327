import React, { useState } from "react";
import SetupTitleTemplate from "./SetupTitleTemplate";
import { useAtom } from "jotai";
import { newCampaignSetupStatusAtom } from "../../../../atoms/CampaignAtom";

const SetupSchedule = ({ minLaunchDate }) => {
  const [displaySetup, setDisplaySetup] = useState(false);
  const [newCampaignSetupStatus, setNewCampaignSetupStatus] = useAtom(
    newCampaignSetupStatusAtom
  );

  const handleNameInput = (e) => {
    const campaignNameValue = e.target.value;

    if (campaignNameValue.trim() !== "") {
      setNewCampaignSetupStatus({
        ...newCampaignSetupStatus,
        name: true,
      });
    } else {
      setNewCampaignSetupStatus({
        ...newCampaignSetupStatus,
        name: false,
      });
    }
  };

  const handleDateInput = (e) => {
    const launchDateValue = e.target.value;

    if (launchDateValue.trim() !== "") {
      setNewCampaignSetupStatus({
        ...newCampaignSetupStatus,
        launchDate: true,
      });
    } else {
      setNewCampaignSetupStatus({
        ...newCampaignSetupStatus,
        launchDate: false,
      });
    }
  };

  const handleHourInput = (e) => {
    const launchHourValue = e.target.value;

    if (launchHourValue.trim() !== "") {
      setNewCampaignSetupStatus({
        ...newCampaignSetupStatus,
        launchHour: true,
      });
    } else {
      setNewCampaignSetupStatus({
        ...newCampaignSetupStatus,
        launchHour: false,
      });
    }
  };

  return (
    <>
      <div className="item-setup-container">
        <SetupTitleTemplate
          setupTitle="PROGRAMMEZ VOTRE CAMPAGNE"
          displaySetup={displaySetup}
          setDisplaySetup={setDisplaySetup}
        />

        <div
          id={displaySetup && "schedule-setup-processing"}
          className={!displaySetup ? "display-none" : "translation-vertical"}
        >
          <div className="schedule-setup ">
            Nom de la campagne
            <br />
            <input
              // required
              maxLength="50"
              type="text"
              name="campaignName"
              className="font-dark-blue1 font-family-designosaur"
              onInput={(e) => handleNameInput(e)}
            />
          </div>
          <div className="schedule-setup ">
            Date de départ
            <br />
            <input
              // required
              type="date"
              name="launchDate"
              min={minLaunchDate}
              className="font-dark-blue1 font-family-designosaur"
              onInput={(e) => handleDateInput(e)}
            />
          </div>
          <div className="schedule-setup ">
            Heure d'envoi
            <br />
            <input
              // required
              type="time"
              name="launchTime"
              className="font-dark-blue1 font-family-designosaur"
              onInput={(e) => handleHourInput(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupSchedule;
