import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { authUserAtom } from "../../../../atoms/AuthUserAtom";
import {
  backendUpdate,
  cleanInput,
  generateCaptcha,
  validateCaptcha,
} from "../../../../utils/functions";
import MainViewTitle from "../../../../components/MainViewTitle/MainViewTitle";
import { useNavigate } from "react-router-dom";
import Announcements from "../../../../components/Announcement/Announcements";

const UserInfosEditionV2 = () => {
  const [authUserData, setAuthUserData] = useAtom(authUserAtom);
  var editedOrganization;
  var editedPhone;
  var editedContact;
  var editedAddress;
  var editedSiret;
  const [phoneInputValidity, setPhoneInputValidity] = useState(true);
  const [siretInputValidity, setSiretInputValidity] = useState(true);
  const [captcha, setCaptcha] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setCaptcha(generateCaptcha());
  }, []);

  const handleChange = (e) => {
    setAuthUserData({ ...authUserData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Captcha checking
    validateCaptcha(e.target.captcha.value, captcha)
      ? setIsCaptchaValid(true)
      : setIsCaptchaValid(false);

    // Check inputs content
    inputsChecking(e);

    // Check phone format
    isPhoneFormatValid(editedPhone)
      ? setPhoneInputValidity(true)
      : setPhoneInputValidity(false);

    // Check siret format
    isSiretFormatValid(editedSiret)
      ? setSiretInputValidity(true)
      : setSiretInputValidity(false);

    if (
      validateCaptcha(e.target.captcha.value, captcha) &&
      isPhoneFormatValid(editedPhone) &&
      isSiretFormatValid(editedSiret)
    ) {
      // Updating the backend with the inputs
      const data = {
        organization: editedOrganization,
        phone: editedPhone,
        contact: editedContact,
        address: editedAddress,
        siret: editedSiret,
      };
      backendUpdate("members", data);

      // Updating local storage with inputs
      authUserData.user.organization = editedOrganization;
      authUserData.user.phone = editedPhone;
      authUserData.user.contact = editedContact;
      authUserData.user.address = editedAddress;
      authUserData.user.siret = editedSiret;
      setAuthUserData(authUserData);

      // Closing the form
      // setDisplayUserInfosEdition(false);
      navigate("/user-infos");
    }
  };

  const inputsChecking = (e) => {
    e.target.organization.value === "" ||
    containsSpacesOnly(e.target.organization.value)
      ? (editedOrganization = authUserData.user.organization)
      : (editedOrganization = cleanInput(e.target.organization.value));

    e.target.contact.value === "" || containsSpacesOnly(e.target.contact.value)
      ? (editedContact = authUserData.user.contact)
      : (editedContact = cleanInput(e.target.contact.value));

    e.target.phone.value === "" || containsSpacesOnly(e.target.phone.value)
      ? (editedPhone = authUserData.user.phone)
      : (editedPhone = cleanInput(e.target.phone.value));

    e.target.address.value === "" || containsSpacesOnly(e.target.address.value)
      ? (editedAddress = authUserData.user.address)
      : (editedAddress = cleanInput(e.target.address.value));

    e.target.siret.value === "" || containsSpacesOnly(e.target.siret.value)
      ? (editedSiret = authUserData.user.siret)
      : (editedSiret = cleanInput(e.target.siret.value));
  };

  const containsSpacesOnly = (string) => {
    const onlySpaces = new RegExp(/^\s*$/);
    return onlySpaces.test(string);
  };

  const isPhoneFormatValid = (phoneNumber) => {
    // const validPhoneFormat = new RegExp(/^0\d{1}\s\d{2}\s\d{2}\s\d{2}\s\d{2}$/); // Phone format 01 02 03 04 05
    const validPhoneFormat = new RegExp(/^0\d{9}$/); // Phone format 0102030405
    return validPhoneFormat.test(phoneNumber);
  };

  const isSiretFormatValid = (siret) => {
    const validSiretFormat = new RegExp(/^\d{14}$/);
    return validSiretFormat.test(siret);
  };

  return (
    <>
      {" "}
      <MainViewTitle
        iconToDisplay={"FaUserCircle"}
        textToDisplay={"VOS INFORMATIONS"}
      />
      <Announcements
        htmlCode={`Merci de compléter les informations suivantes.`}
      />
      <form className="user-infos-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="info-container">
          <div className="info-title">
            <label htmlFor="organization">Société :&nbsp;</label>
          </div>
          <div className="info-content">
            <input
              // required
              maxLength="50"
              type="text"
              className="input"
              aria-label="organization"
              name="organization"
              onInput={(e) => handleChange(e)}
              placeholder={authUserData && authUserData.user.organization}
            />
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">
            <label htmlFor="contact">Personne à contacter :&nbsp;</label>
          </div>
          <div className="info-content">
            <input
              // required
              maxLength="50"
              type="text"
              className="input"
              aria-label="contact"
              name="contact"
              onInput={(e) => handleChange(e)}
              placeholder={authUserData && authUserData.user.contact}
            />
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">
            <label htmlFor="phone">Téléphone :&nbsp;</label>
          </div>
          <div className="info-content">
            <input
              // required
              maxLength="10"
              type="phone"
              className="input"
              aria-label="phone"
              name="phone"
              onInput={(e) => handleChange(e)}
              placeholder={authUserData && authUserData.user.phone}
            />
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">
            <label htmlFor="address">Adresse :&nbsp;</label>
          </div>
          <div className="info-content">
            <input
              // required
              maxLength="50"
              type="text"
              className="input"
              aria-label="address"
              name="address"
              onInput={(e) => handleChange(e)}
              placeholder={authUserData && authUserData.user.address}
            />
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">
            <label htmlFor="siret">SIRET :&nbsp;</label>
          </div>
          <div className="info-content">
            <input
              // required
              maxLength="14"
              type="text"
              className="input"
              aria-label="siret"
              name="siret"
              onInput={(e) => handleChange(e)}
              placeholder={authUserData && authUserData.user.siret}
            />
          </div>
        </div>

        <div className="info-container">
          <div className="info-title">Email :&nbsp;</div>
          <div className="info-content">
            {authUserData && authUserData.user.email}
          </div>
        </div>

        <div className="info-container">
          <>
            <div className="info-title">
              <label htmlFor="captcha" className="padding-right-0-5vw">
                <span className="font-weight-normal">CAPTCHA : recopiez </span>{" "}
                <span className="font-green">{captcha}</span>{" "}
              </label>
            </div>

            <div className="info-content">
              <input
                required
                type="text"
                maxLength={10}
                className="input"
                name="captcha"
              />
            </div>
          </>
        </div>

        <div className="submit-container">
          <div className="button-container">
            <button type="submit" className="blue-button">
              VALIDER
            </button>
          </div>

          <div className="button-container margin-left-1vw">
            <span
              className="red-button"
              onClick={() => navigate("/user-infos")}
            >
              ANNULER
            </span>
          </div>
        </div>

        <div className="input-checking-container font-size-085 text-center font-red">
          {!phoneInputValidity && (
            <>
              <div className="">
                <span className="font-weight-bold font-red">Téléphone : </span>
                <span className="">doit être au format 0102030405</span>
              </div>
            </>
          )}
          {!siretInputValidity && (
            <>
              <div className="">
                <span className="font-weight-bold font-red">SIRET : </span>
                <span className="">doit être composé de 14 chiffres</span>
              </div>
            </>
          )}
          {!isCaptchaValid && (
            <>
              <div className="">
                <span className="font-weight-bold font-red">Captcha : </span>
                <span className="">votre saisie est invalide</span>
              </div>
            </>
          )}
        </div>
      </form>
      <div className="woman-phoning"></div>
      <div className="woman-with-documents"></div>
    </>
  );
};

export default UserInfosEditionV2;
