import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useAtomValue } from "jotai";
import { authUserAtom } from "../../../atoms/AuthUserAtom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { paymentFormAccessAtom } from "../../../atoms/PaymentFormAccessAtom";
import { useNavigate } from "react-router";
import PaymentFormV2 from "./PaymentFormV2";
import { ColorRing } from "react-loader-spinner";
import { campaignPriceAtom } from "../../../atoms/CampaignAtom";
import MainViewTitle from "../../../components/MainViewTitle/MainViewTitle";
import Announcements from "../../../components/Announcement/Announcements";

// Note that the REACT_APP_STRIPE_PUBLIC_KEY is different between LOCAL/DEV environments and PROD environment.
const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentV2 = () => {
  const authUser = useAtomValue(authUserAtom);
  const [clientSecret, setClientSecret] = useState("");
  const paymentFormAccess = useAtomValue(paymentFormAccessAtom);
  const redirect = useNavigate();
  const campaignPrice = useAtomValue(campaignPriceAtom);

  const appearance = {
    theme: "flat",
    labels: "floating",

    variables: {
      colorPrimary: "#51DFAD",
      colorBackground: "#F1F1F1",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "3px",
      borderRadius: "4px",
      fontSizeSm: "0.2rem",
      fontLineHeight: "10px",
    },

    rules: {
      ".Tab": {
        border: "1px solid #E0E6EB",
        display: "none",
        visibility: "hidden",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // Creation of the payment intent through the backend (only if the payment form is "open")
    if (paymentFormAccess === "open") {
      // console.log("CHECK 1");
      if (authUser) {
        // console.log("CHECK 2");
        fetch(process.env.REACT_APP_BACKEND_URL + "charges", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("token"),
          },
          body: JSON.stringify({
            amount: campaignPrice * 100,
            currency: "eur",
            requester: authUser.user,
          }),
        })
          .then((response) => {
            // console.log("CHECK 3", response);
            return response.json();
          })
          .then((data) => {
            // console.log("CHECK 4", data);
            setClientSecret(data.clientSecret);
          });
        // .catch((error) => console.error("ERREUR DE CHARGES =>\n", error.message));
      }
    } else {
      // console.log("CHECK 5");
      redirect("/signinup");
    }
  }, [authUser, paymentFormAccess, redirect, campaignPrice]);

  return (
    <>
      <div>
        <MainViewTitle
          iconToDisplay={"BsCreditCard2Back"}
          textToDisplay={"PAIEMENT"}
        />

        <Announcements htmlCode={"Merci de saisir vos coordonnées bancaires"} />

        <div id="payement-disclaimer">
          <span className="font-size-075">
            <span className="blinking-red-text">Ne changez pas de page</span>{" "}
            pour ne pas perdre votre progression
          </span>
        </div>

        <div id="payement-form-container">
          {/* <span>clientSecret : {clientSecret}</span> */}
          {clientSecret ? (
            <>
              <Elements options={options} stripe={stripeTestPromise}>
                <PaymentFormV2 />
              </Elements>
            </>
          ) : (
            <>
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#232233", "#2b2c40", "#232747", "#5D87FF", "#539BFF"]}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default PaymentV2;
