import React from "react";
import { cleanInput, sendResetInstructions } from "../../../utils/functions";

const ResetInstructionsFormV2 = ({ setDisplayResetInstructionsForm }) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const email = cleanInput(e.target.emailForReset.value);
    sendResetInstructions(email, setDisplayResetInstructionsForm);
  };

  return (
    <>
      <div className="reset-password-modal">
        <form
          className="send-reset-instructions-form"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="">
            <label
              htmlFor="email"
              className="margin-right-1vw margin-bottom-2vh font-dark-blue1"
            >
              Saisissez votre adresse email
              <br />
            </label>
            <input
              required
              maxLength="50"
              type="email"
              name="emailForReset"
              className="width-50pc text-center margin-bottom-2vh font-dark-blue1 border-bottom-1px-solid-black"
            />
          </div>

          <div className="">
            <button type="submit" className="blue-button margin-right-1vw">
              ENVOYER
            </button>
            <button
              className="red-button"
              onClick={() => setDisplayResetInstructionsForm(false)}
            >
              ANNULER
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetInstructionsFormV2;
