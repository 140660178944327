import React from "react";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import iconHameconnage from "../../../images/icone-hameconnage-blanche.png";
import { Link, useNavigate } from "react-router-dom";
// import { useUADetails } from "../../../utils/functions";

const Title = () => {
  const redirect = useNavigate();

  return (
    <>
      <div className="title-container">
        <div className="logo-container" onClick={() => redirect("/")}>
          <span className="font-white font-family-nunito-variablefont">
            PHISH
          </span>{" "}
          <img
            src={iconHameconnage}
            alt="icone d'hammeçonage"
            width="25vw"
            height="auto"
          />{" "}
          <span className="font-white font-family-nunito-variablefont">
            CHEAP
          </span>
          {/* <br />
          <span className="font-size-075">[{
            uADetails.os.name
          }]</span> */}
        </div>

        <div className="social-container">
          <div>
            <Link
              to="https://facebook.com/Alliance-Cyber-Technologies-560385091081859"
              target="_blank"
            >
              <BsFacebook size="1em" color="white" />
            </Link>
          </div>

          <div>
            <Link
              to="https://www.linkedin.com/company/18929139"
              target="_blank"
            >
              <BsLinkedin size="1em" color="white" />
            </Link>
          </div>

          <div>
            <a href={"mailto:contact@alliancecybertech.com"}>
              <MdEmail size="1em" color="white" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Title;
