import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { ColorRing } from "react-loader-spinner";
import CgvV2 from "../../../components/CGV/CgvV2";

const PaymentFormV2 = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [displayCGV, setDisplayCGV] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsPaymentLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:
          process.env.REACT_APP_FRONTEND_URL + "payment/payment-successfull",
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsPaymentLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} id="payement-form">
        <h2 className="margin-bottom-2vh">
          Montant : <span className="font-green">149 € TTC</span>
        </h2>
        <PaymentElement id="payment-element" />
        {isPaymentLoading ? (
          <>
            <div className="text-center">
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#232233", "#2b2c40", "#232747", "#5D87FF", "#539BFF"]}
              />
            </div>
          </>
        ) : (
          <>
            {/* <div className="margin-top-5vh ">
              <span className="text-start font-size-085  margin-top-5vh">
                <input required type="checkbox" className="margin-right-1vw" />
                Cochez la case pour accepter les{" "}
                <span
                  className="cursor-pointer blue-badge"
                  onClick={() => setDisplayCGV(true)}
                >
                  {" "}
                  conditions de ventes
                </span>{" "}
              </span>
            </div> */}

            <div className="cgu-validation-container">
              <div>
                <input
                  required
                  type="checkbox"
                  className="margin-right-0-5vw"
                />
                <span className="font-size-075 margin-right-0-5vw">
                  Cochez la case pour valider les
                </span>
              </div>

              <div>
                <span
                  className="cursor-pointer blue-badge margin-top-0-5vh div-center"
                  onClick={() => setDisplayCGV(true)}
                >
                  conditions de vente
                </span>
              </div>
            </div>

            <div className="margin-top-5vh text-center">
              <button type="submit" className="blue-button">
                Payer
              </button>
            </div>
          </>
        )}

        {displayCGV && (
          <>
            <CgvV2 setDisplayCGV={setDisplayCGV} />
          </>
        )}

        {message && (
          <div id="payment-message" className="my-3">
            {message}
          </div>
        )}
      </form>
    </>
  );
};

export default PaymentFormV2;
