import React, { useEffect } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NewCampaignButton = ({ setMenuSelection, isNewCampaignSelected }) => {
  useEffect(() => {
    setMenuSelection("new-campaign");
  }, [setMenuSelection]);

  const navigate = useNavigate();

  return (
    <>
      <div
        className={
          isNewCampaignSelected
            ? "menu-button-selected"
            : "menu-button"
        }
        onClick={() => navigate("/new-campaign")}
      >
        <div className="menu-button-icon">
          <FaPlusCircle size="1.5em" color="white" />
        </div>

        <div className="menu-button-text">NOUVELLE CAMPAGNE</div>
      </div>
    </>
  );
};

export default NewCampaignButton;
