import sanitizeHtml from "sanitize-html";
import Cookies from "js-cookie";
import { useUA } from "use-ua-parser-js";

// Function to clean inputs forms
export const cleanInput = (dirtyInput) => {
  const cleanCode = sanitizeHtml(dirtyInput, {
    allowedTags: [],
    allowedAttributes: {},
  });

  return cleanCode;
};

// Function to login to backend
export const signIn = (
  login,
  password,
  setAuthUserData,
  setAuthenticationFailed,
  redirect,
  setIsSigninLoding
) => {
  setIsSigninLoding(true);
  fetch(process.env.REACT_APP_BACKEND_URL + "users/sign_in", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      user: {
        email: login,
        password: password,
      },
    }),
  })
    .then((response) => {
      if (response.headers.get("Authorization")) {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 1); // Cookie expires after 1 year
        response.headers.get("Authorization") &&
          Cookies.set("token", response.headers.get("Authorization"), {
            expires: expirationDate,
          });
      } else {
        setAuthenticationFailed(true);
      }
      setIsSigninLoding(false);
      return response.json();
    })

    .then((data) => {
      setAuthUserData(data);
      redirect("/user-infos");
    });
  // .catch(error => console.error("SignIn -> ERROR", error.message))
};

// Function to register in backend
export const signUp = (
  login,
  password,
  setAuthUserData,
  redirect,
  setDidSignUpFail,
  setDoesLoginAlreadyExist,
  setIsSignupLoading
) => {
  setIsSignupLoading(true);
  fetch(process.env.REACT_APP_BACKEND_URL + "users", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      user: {
        email: login,
        password: password,
      },
    }),
  })
    .then((response) => {
      if (response.headers.get("Authorization")) {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 1); // Cookie expires after 1 year
        response.headers.get("Authorization") &&
          Cookies.set("token", response.headers.get("Authorization"), {
            expires: expirationDate,
          });
      } else {
        setDidSignUpFail(true);
      }
      setIsSignupLoading(false);

      return response.json();
    })
    .then((data) => {
      if (
        data.exception ===
        `#<ActiveRecord::RecordNotUnique: PG::UniqueViolation: ERROR:  duplicate key value violates unique constraint "index_users_on_email"\nDETAIL:  Key (email)=(${login}) already exists.\n>`
      ) {
        setDoesLoginAlreadyExist(true);
      } else {
        setAuthUserData(data);
        redirect("/user-infos");
      }
    });
  // .catch(error => console.error("SignUp -> ERROR", error.message))
};

// Function to logout from backend
export const signOut = () => {
  fetch(process.env.REACT_APP_BACKEND_URL + "users/sign_out", {
    method: "DELETE",
    headers: { Authorization: Cookies.get("token") },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      Cookies.remove("token");
      localStorage.removeItem("AuthUserData");
      localStorage.removeItem("NewCampaignElements");
      window.location.replace(process.env.REACT_APP_FRONTEND_URL + "signinup");
    });
  // .catch(error => console.error("SignOut -> ERROR", error.message))
};

// Functions to RESET the user's password
export const sendResetInstructions = (
  email,
  setDisplayResetInstructionsForm
) => {
  fetch(process.env.REACT_APP_BACKEND_URL + "users/password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: { email: email } }),
  })
    .then((response) => {
      response.status === 200
        ? alert(
            `Si ${email} fait partie de nos utilisateurs, un lien de réinitialisation du mot de passe lui a été envoyé par mail.`
          )
        : alert("Erreur : contacter notre assistance si le problème persiste.");
    })
    .then(() => setDisplayResetInstructionsForm(false));
  // .catch(error => console.error("askResetInstructions -> ERROR", error.message))
};

export const resetPassword = (token, password, passwordConfirmation) => {
  fetch(process.env.REACT_APP_BACKEND_URL + `users/password`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user: {
        reset_password_token: token,
        password: password,
        password_confirmation: passwordConfirmation,
      },
    }),
  }).then((response) => {
    response.status === 200
      ? alert("Le mot de passe vient d'être changé, reconnectez-vous :) .")
      : alert("Erreur : contacter notre assistance si le problème persiste.");
  });
  //   .catch(error => console.error("resetPassword -> ERROR :", error.message))
};

// Function to UPDATE any ressource in backend
export const backendUpdate = (route, data) => {
  fetch(process.env.REACT_APP_BACKEND_URL + route, {
    method: "PUT",
    headers: {
      Authorization: Cookies.get("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: data }),
  }).then((response) => {
    if (response.status !== 200) {
      alert("Session expirée : veuillez vous reconnectez.");
      Cookies.remove("token");
      window.location.replace(process.env.REACT_APP_FRONTEND_URL + "signinup");
    }
    return response.json();
  });
  // .catch(error => console.error("backendUpdate #members -> ERROR\n", error.message))
};

// Function to get all the campaigns of the current_user from backend
export const getCurrentUserCampaigns = (setCurrentUserCampaigns) => {
  fetch(process.env.REACT_APP_BACKEND_URL + "current-user-campaigns", {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token"),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        alert("Session expirée : veuillez vous reconnectez.");
        Cookies.remove("token");
        window.location.replace(
          process.env.REACT_APP_FRONTEND_URL + "signinup"
        );
      }
      return response.json();
    })
    .then((data) => {
      // console.log("getCurrentUserCampaigns DATA :\n", data)
      setCurrentUserCampaigns(data);
    });
  // .catch(error => console.error("getCurrentUserCampaigns -> ERROR\n", error.message))
};

// Function to get a campaign summary from Gophish
export const getCampaignSummary = (campaignGophishID, setSummary) => {
  fetch(
    process.env.REACT_APP_GOPHISH_API +
      `campaigns/${campaignGophishID}/summary/?api_key=${process.env.REACT_APP_GOPHISH_API_KEY}`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      response.status !== 200 &&
        alert("Erreur : contacter notre assistance si le problème persiste.");
      return response.json();
    })
    .then((data) => {
      setSummary(data);
    });
  // .catch(error => console.error("getCampaignSummary -> ERROR\n", error.message));
};

// Functions related to CAPTCHA
// Generate a 6 random characters string
export const generateCaptcha = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";

  for (let i = 0; i < 6; i++) {
    let randomIndex = Math.floor(Math.random() * characters.length);
    captcha += characters[randomIndex];
  }

  return captcha;
};

// Compare user's input with the generated captcha, return true if there is a match.
export const validateCaptcha = (input, captcha) => {
  return input === captcha ? true : false;
};

// Function that collect user device informations in order to figure out the responsive styles
export const useUADetails = () => {
  console.log(useUA());
  return useUA();
};

// Function that parse à phone number from 0102030405 to 01 02 03 04 05 (in order to be displayed)
export const formatPhoneNumber = (input) => {
  // Remove all non numerics characters
  const formatted = input.replace(/\D/g, "");

  // Parse into 2 digits couples
  let result = "";
  for (let i = 0; i < formatted.length; i += 2) {
    result += formatted.slice(i, i + 2) + " ";
  }

  // Remove the last space at the end of 'result'
  if (result.endsWith(" ")) {
    result = result.slice(0, -1);
  }

  return result;
};
