import React from "react";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";

import {
  BsFill1CircleFill,
  BsFill2CircleFill,
  BsFill3CircleFill,
  BsFill4CircleFill,
} from "react-icons/bs";

const SetupTitleTemplate = ({ setupTitle, displaySetup, setDisplaySetup }) => {
  return (
    <>
      <div
        className="setup-title cursor-pointer"
        onClick={() => setDisplaySetup(!displaySetup)}
      >
        <div className="title-part">
          <div className="title-icon">
            {setupTitle === "CHOISISSEZ LE FAUX PHISHING" && (
              <BsFill1CircleFill size="1.5em" className="setup-step" />
            )}
            {setupTitle === "ENVOYEZ LES @ EMAILS CIBLÉES (50 MAXIMUM)" && (
              <BsFill2CircleFill size="1.5em" className="setup-step" />
            )}
            {setupTitle === "PROGRAMMEZ VOTRE CAMPAGNE" && (
              <BsFill3CircleFill size="1.5em" className="setup-step" />
            )}
            {setupTitle === "AUTORISATION" && (
              <BsFill4CircleFill size="1.5em" className="setup-step" />
            )}{" "}
          </div>
          <div className="title-text">
            {setupTitle}
          </div>
        </div>

        <div className="arrow-part">
          {displaySetup ? (
            <>
              {" "}
              <IoIosArrowDropupCircle
                size="1.5em"
                color="white"
                className="border-1px-solid-white border-radius-50pc"
              />
            </>
          ) : (
            <>
              {" "}
              <IoIosArrowDropdownCircle
                size="1.5em"
                color="white"
                className="border-1px-solid-white border-radius-50pc"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SetupTitleTemplate;
