import React, { useEffect, useState } from "react";
import { getCampaignSummary } from "../../../utils/functions";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const MyCampaignSummaryV2 = ({ gophishId }) => {
  const [summary, setSummary] = useState();

  const data = {
    labels: ["Envoyés", "Ouverts", "Cliqués"],
    datasets: [
      {
        label: "Emails",
        data: [
          // summary && summary.stats.total,
          summary && summary.stats.sent,
          summary && summary.stats.opened,
          summary && summary.stats.clicked,
        ],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(247, 102, 102, 1)",
        ],
        borderColor: [
          "rgba(19, 222, 185, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(247, 102, 102, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "left",
        labels: {
          color: "white",
        },
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] > 0, // Disply only value that are greater than zero
        color: "white",
        formatter: (value) => value,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  useEffect(() => {
    getCampaignSummary(gophishId, setSummary);
  }, [gophishId, setSummary]);

  return (
    <>
      <div className="summary-chart">
        <Doughnut data={data} options={options} />
      </div>
    </>
  );
};

export default MyCampaignSummaryV2;
