import React, { useState } from "react";

const GophishTools = () => {
  const [landingPages, setLandingPages] = useState(null);

  const getLandginPagesList = () => {
    setLandingPages(null);

    fetch(
      process.env.REACT_APP_GOPHISH_API +
        `pages/?api_key=${process.env.REACT_APP_GOPHISH_API_KEY}`,
      {
        method: "GET",
        // headers: {
        //   'Authorization': process.env.REACT_APP_GOPHISH_API_KEY,
        // }
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Échec de la requête.");
        }
      })
      .then((data) => {
        // Maintenant, vous pouvez traiter les données de la liste des landing pages
        setLandingPages(data);
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div
        className="margin-top-5vh cursor-pointer margin-left-1vw"
        onClick={() => getLandginPagesList()}
      >
        Get Landing Pages
      </div>
      <div className="margin-top-2vh margin-left-2vw">
          {landingPages &&
            landingPages.map((pages) => (
              <>
                <div className="font-white">
                  {pages.id}. {pages.name}
                </div>
              </>
            ))}
      </div>
    </>
  );
};

export default GophishTools;
