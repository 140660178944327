import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const campaignPriceAtom = atom(149);

export const newCampaignElementsAtom = atomWithStorage(
  "NewCampaignElements",
  {
    name: "",
    templateName: "",
    templateScreenshot:
      "/static/media/templateMailbox.128114ce28dba32e7a13.png",
    emailsList: [],
    usersGroupName: "",
    launchDate: "",
    duration: 1,
    sendBy: new Date(),
    acceptance: false,
  }
);

export const newCampaignSetupStatusAtom = atom({
  template: false,
  emailsList: false,
  name: false,
  launchDate: false,
  launchHour: false,
  acceptance: false,
});
