import React, { useEffect } from "react";
import { previousPageAtom } from "../../../atoms/PreviousPageAtom";
import { authUserAtom } from "../../../atoms/AuthUserAtom";
import { useSetAtom, useAtomValue } from "jotai";
import { Link } from "react-router-dom";
import CampaignSetUpV2 from "./CampaignSetUpV2";
import { campaignPriceAtom } from "../../../atoms/CampaignAtom";
import MainViewTitle from "../../../components/MainViewTitle/MainViewTitle";
import Announcements from "../../../components/Announcement/Announcements";

const NewCampaignV2 = () => {
  const setPreviousPage = useSetAtom(previousPageAtom);
  const userData = useAtomValue(authUserAtom);
  const CampaignPrice = useAtomValue(campaignPriceAtom);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Reset of the previousPageAtom otherwise the CampaignProcessing component will be redirected to the current component and not to the MyCampaigns component.
    setPreviousPage(null);
  }, [setPreviousPage]);

  return (
    <>
      <div>
        <MainViewTitle
          iconToDisplay={"FaPlusCircle"}
          textToDisplay={"NOUVELLE CAMPAGNE"}
        />

        <Announcements
          htmlCode={`Paramétrez votre campagne de faux-phishing pour <span class="font-weight-bold font-green"> ${CampaignPrice}€ TTC</span>.`}
        />

        <div className="margin-top-5vh text-center">
          {userData &&
          (userData.user.organization === "" ||
            userData.user.phone === "" ||
            userData.user.contact === "" ||
            userData.user.address === "" ||
            userData.user.siret === "") ? (
            <>
              Avant de créer une nouvelle campagne, merci de compléter vos
              informations en cliquants sur
              <div className="margin-top-5vh">
                <Link to="/user-infos-edition" className="text-decoration-none">
                  <button className="green-button">VOS INFORMATIONS</button>
                </Link>
              </div>
              <div className="man-rising-hand"></div>
            </>
          ) : (
            <>
              <CampaignSetUpV2 />
            </>
          )}
        </div>
      </div>

      <div className="man-reading"></div>
      <div className="man-rising-hand"></div>
    </>
  );
};

export default NewCampaignV2;
