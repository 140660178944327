import React from "react";

const CookiesPolicy = ({ setDisplayCookiesPolicy }) => {
  return (
    <>
      <div id="cookies-policy-modal-container">
        <div id="cookies-policy-container">
          <h2 className="text-center margin-top-2vh">Politique de Cookies</h2>

          <h5 className="margin-top-1vh margin-bottom-5vh">
            Dernière mise à jour : 16/10/2023
          </h5>

          <p>
            Cette Politique de Cookies explique comment nous utilisons les
            cookies et autres technologies similaires sur notre site internet{" "}
            <span className="font-weight-bold">
              {process.env.REACT_APP_FRONTEND_URL}
            </span>{" "}
            (le "Site") exploité par{" "}
            <span className="font-weight-bold">
              Alliance Cyber Technologies
            </span>{" "}
            (ci-après dénommé "nous", "notre", ou "nos").
          </p>
          <h3 className="margin-top-5vh">Qu'est-ce qu'un cookie ?</h3>
          <p>
            Un cookie est un petit fichier de données stocké sur l'appareil de
            l'utilisateur lorsqu'il visite un site internet. Les cookies sont
            largement utilisés pour garantir le bon fonctionnement d'un site,
            pour collecter des informations sur la manière dont les visiteurs
            utilisent le site, et pour personnaliser l'expérience de
            l'utilisateur.
          </p>
          <h3 className="margin-top-5vh">
            Comment utilisons-nous les cookies ?
          </h3>
          <p>
            Nous utilisons des cookies sur notre Site pour les finalités
            suivantes :
          </p>
          <ul className="margin-left-3vw margin-right-3vw margin-top-5vh">
            <li className="margin-bottom-5vh">
              <p>
                <strong>Fonctionnalité essentielle</strong> : nous utilisons des
                cookies essentiels pour vous permettre de naviguer sur le Site
                et d'utiliser ses fonctionnalités de base. Ces cookies sont
                nécessaires pour que le Site fonctionne correctement, ils sont
                toujours actifs.
              </p>
            </li>
            {/* <li className="margin-bottom-5vh"> KEEP THIS PART FOR A LATER USE
                <p>
                  <strong>Analyse et performance</strong> : nous utilisons des cookies pour
                  collecter des informations sur la manière dont les visiteurs
                  utilisent le Site. Ces données nous aident à améliorer le Site
                  et à comprendre comment il est utilisé.
                </p>
              </li> */}
            <li className="margin-bottom-5vh">
              <p>
                <strong>Stockage local (Local Storage)</strong> : dans certains
                cas, nous pouvons stocker des informations dans la
                mémoire du navigateur afin de procéder à l'exécution de
                fonctionnalités essentielles. Ces stockages sont toujours
                actifs.
              </p>
            </li>
          </ul>
          <h3 className="margin-top-5vh">Vos possibilités</h3>
          <p>
            Vous avez le droit de choisir d'accepter ou de refuser les cookies
            en configurant les paramètres de votre navigateur. La plupart des
            navigateurs acceptent automatiquement les cookies, mais vous pouvez
            généralement modifier les paramètres de votre navigateur pour
            refuser les cookies si vous le souhaitez. Notez que le refus des
            cookies rendra impossible l'utilisation convenable de notre Site.
          </p>
          <h3 className="margin-top-5vh">Consentement</h3>
          <p>
            En utilisant notre Site, vous consentez à notre utilisation de
            cookies conformément à cette Politique de Cookies. Si vous ne
            consentez pas à l'utilisation de cookies, veuillez configurer les
            paramètres de votre navigateur pour refuser les cookies ou ne pas
            utiliser notre Site.
          </p>
          <h3 className="margin-top-5vh">Contact</h3>
          <p>
            Si vous avez des questions concernant notre Politique de Cookies,
            veuillez nous contacter à contact[@]alliancecybertech.com.
          </p>

          <h3 className="margin-top-5vh">
            Modifications de la Politique de Cookies
          </h3>
          <p>
            Nous nous réservons le droit de mettre à jour cette Politique de
            Cookies à tout moment. Toute modification de cette politique sera
            publiée sur cette page.
          </p>

          <div className="button-container margin-top-5vh">
            <button
              onClick={() => setDisplayCookiesPolicy(false)}
              className="light-blue1-button"
            >
              FERMER
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiesPolicy;
