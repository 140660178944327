import React, { useEffect, useState } from "react";
import { getCurrentUserCampaigns } from "../../../utils/functions";
import { previousPageAtom } from "../../../atoms/PreviousPageAtom";
import { useSetAtom } from "jotai";
import { useAtomValue } from "jotai";
import YourCampaignDetailsV2 from "./YourCampaignDetailsV2";
import { useNavigate } from "react-router-dom";
import MainViewTitle from "../../../components/MainViewTitle/MainViewTitle";
import Announcements from "../../../components/Announcement/Announcements";

const YourCampaignsV2 = () => {
  const [currentUserCampaigns, setCurrentUserCampaigns] = useState();
  const setPreviousPage = useSetAtom(previousPageAtom);
  const previousPage = useAtomValue(previousPageAtom);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    // Here we need to save the current URL in order to avoid the browser back button, which redirect us to the CampaignProcessing component and that will create network errors.
    setPreviousPage(window.location.href);
    getCurrentUserCampaigns(setCurrentUserCampaigns);
  }, [setPreviousPage, previousPage]);

  return (
    <div>
      <MainViewTitle
        iconToDisplay={"ImStatsBars"}
        textToDisplay={"VOS CAMPAGNES"}
      />

      <Announcements
        htmlCode={`  Demandez une analyse de vos résultats par un expert en cybersécurité à
        <br />
        <span class="font-weight-bold font-dark-blue1">
          contact<span class="  font-dark-blue1 margin-left-0-1vw margin-right-0-1vw">@</span>alliancecybertech.com
        </span>`}
      />

      {currentUserCampaigns && currentUserCampaigns.length === 0 ? (
        <>
          <div
            className="margin-top-10vh div-center green-button text-center"
            onClick={() => navigate("/new-campaign")}
          >
            NOUVELLE CAMPAGNE
          </div>
        </>
      ) : (
        <>
          {" "}
          <div id="all-campaigns-container">
            <div id="campaigns-header">
              <div className="header-text-container">
                <span className="header-text">ÉTAT</span>
              </div>

              <div className="header-text-container">
                <span className="header-text">CAMPAGNE</span>
              </div>

              <div className="header-text-container">
                <span className="header-text">DATE DE DÉPART</span>
              </div>

              <div className="header-text-container">
                <span className="header-text">FAUX PHISHING ENVOYÉ</span>
              </div>
            </div>
            {currentUserCampaigns &&
              currentUserCampaigns.map((campaign) => (
                <>
                  <YourCampaignDetailsV2 campaign={campaign} />
                </>
              ))}
          </div>
          <div className="man-bubble-idea"></div>
          <div className="woman-sitting-with-computer"></div>
        </>
      )}
    </div>
  );
};

export default YourCampaignsV2;
