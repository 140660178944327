import React from "react";
import { cleanInput } from "../../../../utils/functions";

const SystemTestForm = ({ setDisplaySystemTestForm }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const emailForTest = cleanInput(e.target.email.value);
    processSystemTestCampaign(emailForTest);

    setDisplaySystemTestForm(false);
  };

  const processSystemTestCampaign = (email) => {
    createSystemTestGroup(email);
  };

  const createSystemTestGroup = (email) => {
    const randomSuffix = Math.floor(Math.random() * 1000).toString();
    const groupName = "Test for " + email + "_" + randomSuffix;

    fetch(
      `${process.env.REACT_APP_GOPHISH_API}groups/?api_key=${process.env.REACT_APP_GOPHISH_API_KEY}`,
      {
        method: "POST",
        body: JSON.stringify({
          name: groupName,
          targets: [
            {
              email: email,
              first_name: "Test",
              last_name: "Test",
              position: "Test",
            },
          ],
        }),
      }
    )
      .then((response) => {
        // console.log("createSystemTestGroup Response :\n", response);

        // Depending on the browser used, the response could be "Created" (for Firefox) or "" (for Chrome and Opera)
        if (response.statusText !== "Created" && response.statusText !== "") {
          alert(
            "Erreur lors du transfert de la liste d'emails.\nContacter notre assistance si le problème persiste."
          );
        }

        return response.json();
      })
      // .then((data) => {
      //   console.log("createSystemTestGroup data :\n", data);
      // })
      .then(() => {
        createSystemTestCampaign(groupName, email);
      });
    // .catch(error => console.error("createAUsersGroupInGophish -> ERROR\n", error.message))
  };

  const createSystemTestCampaign = (groupName, email) => {
    fetch(
      `${process.env.REACT_APP_GOPHISH_API}campaigns/?api_key=${process.env.REACT_APP_GOPHISH_API_KEY}`,
      {
        method: "POST",
        body: JSON.stringify({
          name: groupName,
          template: { name: "System Test (depuis Phish&Cheap)" },
          url: process.env.REACT_APP_GOPHISH_SERVER,
          page: { name: "[PIÈGE NON RÉVÉLÉ] - Réessayez plus tard" },
          smtp: { name: "Sendgrid (administrateur@notification-info.fr)" },
          launch_date: new Date(),
          send_by_date: null,
          groups: [{ name: groupName }],
        }),
      }
    ).then((response) => {
      // Depending on the browser used, the response could be "Created" (for Firefox) or "" (for Chrome and Opera)
      if (response.statusText !== "Created" && response.statusText !== "") {
        alert(
          "Erreur lors de la création de la campagne.\nContacter notre assistance si le problème persiste."
        );
      } else {
        alert(
          `Un email avec l'objet 'Test de Phish&Cheap' vient d'être envoyé sur ${email}.\n\nVous pouvez vérifier qu'il arrive bien dans la boîte de réception.`
        );
      }
      return response.json();
    });
    // .then((data) => {
    //   console.log("createSystemTestCampaign DATA :\n", data);
    // });
    // .catch(error => console.error(error.message))
  };

  return (
    <>
      <div className="system-test-modal">
        <form
          className="system-test-form text-center"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="">
            <label
              htmlFor="email"
              className="margin-right-1vw margin-bottom-2vh font-dark-blue1"
            >
              Saisissez une adresse email pour recevoir ce test
              <br />
            </label>
            <input
              required
              maxLength="50"
              type="email"
              name="email"
              className="width-50pc text-center margin-bottom-2vh font-dark-blue1 border-bottom-1px-solid-black"
            />
          </div>
          <div className="">
            <button type="submit" className="blue-button margin-right-1vw">
              ENVOYER
            </button>
            <button
              className="red-button"
              onClick={() => setDisplaySystemTestForm(false)}
            >
              ANNULER
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SystemTestForm;
