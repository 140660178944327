import React from "react";
import { BsEmojiDizzy } from "react-icons/bs";

const Page404V2 = () => {
  return (
    <>
      <div className="url-error-global-container">
        <div >
          <BsEmojiDizzy size="10em" />
        </div>
        <div className="margin-top-5vh">
          <strong>Erreur : vérifiez l'URL saisie.</strong>
        </div>
      </div>
    </>
  );
};

export default Page404V2;
