import React, { useEffect } from "react";
import iconHameconnage from "../../../images/icone-hameconnage-blanche.png";
import { Link } from "react-router-dom";
import logoACT from "../../../images/logo2023-act.png";
import { useSetAtom } from "jotai";
import {
  isNewCampaignSelectedAtom,
  isSystemTestSelectedAtom,
  isUserAccountSelectedAtom,
  isYourCampaignsSelectedAtom,
  menuSelectionAtom,
} from "../../../atoms/MenuSelectionAtom";

const HomepageV2 = () => {
  const setMenuSelection = useSetAtom(menuSelectionAtom);
  const setIsUserAccountSelected = useSetAtom(isUserAccountSelectedAtom);
  const setIsNewCampaignSelected = useSetAtom(isNewCampaignSelectedAtom);
  const setIsYourCampaignsSelected = useSetAtom(isYourCampaignsSelectedAtom);
  const setIsSystemTestSelected = useSetAtom(isSystemTestSelectedAtom);

  useEffect(() => {
    window.scrollTo(0, 0);
    const resetMenuSelection = () => {
      setMenuSelection("no-selection");
      setIsUserAccountSelected(false);
      setIsNewCampaignSelected(false);
      setIsYourCampaignsSelected(false);
      setIsSystemTestSelected(false);
    };

    resetMenuSelection();
  }, [
    setMenuSelection,
    setIsUserAccountSelected,
    setIsNewCampaignSelected,
    setIsYourCampaignsSelected,
    setIsSystemTestSelected,
  ]);

  return (
    <>
      <div className="homepage-text-container">
        <h1 className="font-family-nunito-variablefont margin-bottom-5vh">
          PHISH{" "}
          <img
            src={iconHameconnage}
            alt="icone d'hammeçonage"
            width="35vw"
            height="auto"
          />{" "}
          CHEAP
        </h1>

        <h4 className="margin-bottom-2vh">
          <strong>Campagnes anti-phishing automatisées</strong>
        </h4>

        <Link
          to="https://alliancecybertech.eu/"
          target="_blank"
          className="text-decoration-none"
        >
          <div className="act-link">
            <div className="act-name">
              <span className="font-white font-size-075 font-style-italic ">
                par Alliance Cyber Technologies
              </span>
            </div>
            <div className="act-logo">
              <img
                src={logoACT}
                alt="logo de la société alliance cyber technologies"
                width="20vw"
                height="20vw"
              />
            </div>
          </div>
        </Link>
      </div>

      <div className="women-credit-card"></div>
      <div className="man-with-mask"></div>
    </>
  );
};

export default HomepageV2;
