import React from "react";

const Announcements = ({ htmlCode }) => {
  return (
    <>
      {/* dangerouslySetInnerHTML is used to inject raw Html code  */}
      <div
        className="announcement-container"
        dangerouslySetInnerHTML={{ __html: htmlCode }}
      ></div>
    </>
  );
};

export default Announcements;
