import React from "react";
import Title from "./components/Title";
import SigninupButton from "./components/SigninupButton";

const Header = () => {
  return (
    <>
      <header>
        <div className="header-container">
          <Title />

          <SigninupButton />
        </div>
      </header>
    </>
  );
};

export default Header;
