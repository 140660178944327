import React, { useState } from "react";
import CookiesPolicy from "./components/CookiesPolicy";
import Cookies from "js-cookie";

const CookiesBanner = ({ setDisplayCookiesBanner }) => {
  const [displayCookiesPolicy, setDisplayCookiesPolicy] = useState(false);

  const handleClick = () => {
    Cookies.set(process.env.REACT_APP_COOKIES_POLICY_VALIDATION, true);
    setDisplayCookiesBanner(false);
  };

  return (
    <>
      <div id="cookies-banner-container" className="cookies-banner-animation">
        <div id="cookies-disclaimer-container">
          <h3 className="font-dark-blue1">Cookies</h3>
          <div className="font-dark-blue1">
            Notre site utilise des cookies pour en faciliter son usage.
            Consultez notre{" "}
            <span
              className="font-dark-blue1 font-weight-bold cursor-pointer"
              onClick={() => setDisplayCookiesPolicy(true)}
            >
              <u>politique de cookies</u>
            </span>{" "}
            pour en savoir plus.
          </div>
        </div>

        <div id="cookies-buttons-container">
          <button className="orange-button" onClick={() => handleClick()}>
            J'ai compris
          </button>
        </div>
      </div>

      {displayCookiesPolicy && (
        <CookiesPolicy setDisplayCookiesPolicy={setDisplayCookiesPolicy} />
      )}
    </>
  );
};

export default CookiesBanner;
