import React, { useEffect } from "react";
import { FaLightbulb } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SystemTestButton = ({ setMenuSelection, isSystemTestSelected }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setMenuSelection("system-test");
  }, [setMenuSelection]);

  return (
    <>
      <div
        className={
          isSystemTestSelected
            ? "menu-button-selected"
            : "menu-button"
        }
        onClick={() => navigate("/system-test")}
      >
        <div className="menu-button-icon">
          <FaLightbulb size="1.5em" color="yellow" />
        </div>

        <div className="menu-button-text">TESTEZ VOTRE SYSTÈME</div>
      </div>
    </>
  );
};

export default SystemTestButton;
