import React, { useEffect, useState } from "react";
import { authUserAtom } from "../../../atoms/AuthUserAtom";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import SignInFormV2 from "./components/SignInFormV2";
import SignUpFormV2 from "./components/SignUpFormV2";

const SignInUpV2 = () => {
  // const [signUpStyle, setSignUpStyle] = useState("");
  // const [signInStyle, setSignInStyle] = useState("");
  const authUser = useAtomValue(authUserAtom);
  const navigate = useNavigate();
  const [FormToDisplay, whichFormToDisplay] = useState("signin");

  useEffect(() => {
    authUser && navigate("/user-infos");
  }, [authUser, navigate]);

  return (
    <>
      {FormToDisplay === "signin" && (
        <SignInFormV2 whichFormToDisplay={whichFormToDisplay} />
      )}

      {FormToDisplay === "signup" && (
        <SignUpFormV2 whichFormToDisplay={whichFormToDisplay} />
      )}

    </>
  );
};

export default SignInUpV2;
