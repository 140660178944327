import React, { useState } from "react";
import * as XLSX from "xlsx";
import template_XLSX from "../group_template.xlsx";
import SetupTitleTemplate from "./SetupTitleTemplate";
import { useAtom } from "jotai";
import {
  newCampaignElementsAtom,
  newCampaignSetupStatusAtom,
} from "../../../../atoms/CampaignAtom";

const SetupEmailsList = () => {
  var eventGlobalScope;
  const [displaySetup, setDisplaySetup] = useState(false);
  const [newCampaignSetupStatus, setNewCampaignSetupStatus] = useAtom(
    newCampaignSetupStatusAtom
  );
  const [newCampaignElements, setNewCampaignElements] = useAtom(
    newCampaignElementsAtom
  );

  const handleXLSXUpload = (event) => {
    eventGlobalScope = event;

    var uploadedFile = event.target.files[0];

    // File size validation
    const isSizeValid = fileSizeValidation(uploadedFile);

    // Format file validation
    const isTypeValid = fileTypeValidation(uploadedFile);

    // Parsing processing if size and format are valid
    if (isSizeValid && isTypeValid) {
      XLSXParsing();
      setNewCampaignSetupStatus({
        ...newCampaignSetupStatus,
        emailsList: true,
      });
      alert("Votre liste d'adresses email est bien prise en compte.\n");
    } else {
      event.target.value = "";
      alert(
        "Votre fichier ne peut être pris en charge.\nSoit il est supérieur à 1 Mb, soit le type de fichier est incorrecte.\n\nMerci d'utiliser notre modèle à télécharger.\n"
      );
    }
  };

  const XLSXParsing = () => {
    // File parsing
    const reader = new FileReader();
    reader.readAsBinaryString(eventGlobalScope.target.files[0]);
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      var parsedData = XLSX.utils.sheet_to_json(sheet);

      // File headers validation
      const areHeadersValid = fileHeadersValidation(parsedData);

      var areEmailsValid;
      var isCodeClean;
      if (areHeadersValid) {
        // Force data to string type
        forceStringType(parsedData);
        // Emails validation
        areEmailsValid = emailsFormatValidation(parsedData);
        // Code injection detection
        isCodeClean = codeCleanChecking(parsedData);
      }

      if (areEmailsValid && areHeadersValid && isCodeClean) {
        const emailsLimit = 50; // Limit to 50 emails
        
        parsedData.length > emailsLimit &&
          alert(
            "La campagne sera envoyée sur les 50 premières adresses emails.\n\nPour réaliser une campagne supérieure à 50 emails, contactez nous à contact@alliancecybertech.com."
          );

        const finalEmailsList = parsedData.slice(0, emailsLimit);

        // console.log("XLSXParsing finalEmailsList :\n", finalEmailsList);

        setNewCampaignElements({
          ...newCampaignElements,
          emailsList: finalEmailsList,
        });

        setNewCampaignSetupStatus({
          ...newCampaignSetupStatus,
          emailsList: true,
        });
      } else {
        eventGlobalScope.target.value = "";
        alert(
          "Les données ne peuvent être utilisées. Merci de vérifier votre fichier."
        );
      }
    };
  };

  const fileSizeValidation = (file) => {
    return file.size <= 1000000 ? true : false;
  };

  const fileTypeValidation = (file) => {
    return file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ? true
      : false;
  };

  const forceStringType = (data) => {
    data = data.map((entry) => {
      entry["first_name"] = entry["first_name"].toString();
      entry["last_name"] = entry["last_name"].toString();
      entry["email"] = entry["email"].toString();
      entry["position"] = entry["position"].toString();
      return entry;
    });
  };

  const emailsFormatValidation = (usersList) => {
    const invalidEmails = [];

    var validRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    usersList.forEach((entry) => {
      !entry["email"].match(validRegex) && invalidEmails.push(entry["email"]);
    });

    if (invalidEmails.length === 0) {
      return true;
    } else {
      alert(
        "Certains emails contiennent des erreurs, merci de les corriger :\n\n" +
          invalidEmails.join("\n")
      );
      return false;
    }
  };

  const fileHeadersValidation = (data) => {
    if (
      Object.keys(data[0])[0] === "first_name" &&
      Object.keys(data[0])[1] === "last_name" &&
      Object.keys(data[0])[2] === "email" &&
      Object.keys(data[0])[3] === "position"
    ) {
      return true;
    } else {
      alert(
        "Les en-têtes de vos données sont incorrectes.\n\nMerci d'utiliser notre modèle à télécharger pour éviter ces erreurs ou corrigez vos en-têtes qui doivent être :\n\nfirst_name, last_name, email, position"
      );
      return false;
    }
  };

  const codeCleanChecking = (data) => {
    var regExInjectionCode =
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

    const codeDetected = [];

    var regExOnlyNames = /^[a-zA-ZÀ-ÿÇç'’\d -]+(?: [a-zA-ZÀ-ÿÇç'’\d -]+)*$/;

    const notNames = [];

    data.forEach((entry) => {
      // Check code injection in each entry
      entry["first_name"].match(regExInjectionCode) &&
        codeDetected.push(entry["first_name"]);
      entry["last_name"].match(regExInjectionCode) &&
        codeDetected.push(entry["last_name"]);
      entry["position"].match(regExInjectionCode) &&
        codeDetected.push(entry["position"]);

      // Check if data are names in each entry
      !entry["first_name"].match(regExOnlyNames) &&
        notNames.push(entry["first_name"]);
      !entry["last_name"].match(regExOnlyNames) &&
        notNames.push(entry["last_name"]);
      !entry["position"].match(regExOnlyNames) &&
        notNames.push(entry["position"]);
    });

    if (codeDetected.length === 0 && notNames.length === 0) {
      return true;
    } else {
      alert(
        "Des données semblent invalides, merci de les corriger :\n\n" +
          codeDetected.join("\n") +
          "\n" +
          notNames.join("\n")
      );
      return false;
    }
  };

  return (
    <>
      <div className="item-setup-container">
        <SetupTitleTemplate
          setupTitle="ENVOYEZ LES @ EMAILS CIBLÉES (50 MAXIMUM)"
          displaySetup={displaySetup}
          setDisplaySetup={setDisplaySetup}
        />

        <div
          id={displaySetup && "group-setup-processing"}
          className={!displaySetup ? "display-none" : "translation-vertical"}
        >
          <div className="group-setup">
            1. D'abord, saisissez les emails de vos collaborateurs à l'aide de{" "}
            <a
              href={template_XLSX}
              download="group_template.xlsx"
              className="text-decoration-none"
            >
              <span className="blue-badge">notre fichier modèle</span>
            </a>
          </div>

          <div className="group-setup">
            2. Ensuite, envoyez votre fichier à l'aide du bouton
            {/* File Uploader */}
            <input
              // required
              type="file"
              accept=".xlsx, .xls"
              onChange={handleXLSXUpload}
              className="margin-left-1vw"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupEmailsList;
