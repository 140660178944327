import React, { useEffect } from "react";
import {
  // campaignProcessingAtom,
  newCampaignElementsAtom,
} from "../../../atoms/CampaignAtom";
import { useAtom, useAtomValue } from "jotai";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { previousPageAtom } from "../../../atoms/PreviousPageAtom";
import { paymentFormAccessAtom } from "../../../atoms/PaymentFormAccessAtom";
import MainViewTitle from "../../../components/MainViewTitle/MainViewTitle";
import Announcements from "../../../components/Announcement/Announcements";

const CampaignSetUpControlV2 = () => {
  const newCampaignElements = useAtomValue(newCampaignElementsAtom);
  const redirect = useNavigate();
  // const setCampaignProcessing = useSetAtom(campaignProcessingAtom);
  const [previousPage, setPreviousPage] = useAtom(previousPageAtom);
  const setPaymentFormAccess = useSetAtom(paymentFormAccessAtom);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // The user is not allowed to access the current page from any other page than new-campaign, because the campaign set up can't be called from another page.
    if (previousPage !== "/new-campaign") {
      redirect("/new-campaign");
    }

    // Here we prevent the user of refresh the page, otherwise data will be lost
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Line needed to custom the dialog box

      // Display the dialog box
      const confirmationMessage =
        "Êtes-vous sûr de vouloir quitter cette page ?";
      event.returnValue = confirmationMessage; // Some browsers need the value to be returned

      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [newCampaignElements, previousPage, redirect]);

  const openPaymentForm = () => {
    // The payment form is closed by default (protection to force the form to be accessible after a campaign set up only).
    setPaymentFormAccess("open");
    setPreviousPage(location.pathname);
    redirect("/payment");
  };

  return (
    <>
      <div>
        <MainViewTitle
          iconToDisplay={"BsFillJournalBookmarkFill"}
          textToDisplay={"RÉCAPITULATIF"}
        />

        <Announcements htmlCode={"Les informations sont-elles exactes ?"} />

        <div id="summary-buttons-container">
          <button className="green-button" onClick={() => openPaymentForm()}>
            {/* <IoIosArrowDroprightCircle size="1.75em" /> */}
            VALIDER
          </button>

          <button
            className="orange-button margin-left-1vw"
            onClick={() => redirect("/new-campaign")}
          >
            {/* <IoIosArrowDropleftCircle size="1.75em" />*/}
            RECOMMENCER
          </button>
        </div>

        <div id="summary-infos-container">
          <div className="single-info-container">
            <div className="single-info-title">
              <span className="font-weight-bold">Nom de la campagne</span>{" "}
            </div>
            <div className="single-info-content">
              <span className="font-family-nunito-variablefont">
                {newCampaignElements.name}
              </span>
            </div>
          </div>

          <div className="single-info-container">
            <div className="single-info-title">
              <span className="font-weight-bold">Date de départ</span>{" "}
            </div>
            <div className="single-info-content">
              <span className="font-family-nunito-variablefont">
                le{" "}
                {new Date(newCampaignElements.launchDate).toLocaleDateString(
                  "fr-FR",
                  {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )}{" "}
                à {newCampaignElements.launchTime}
              </span>
            </div>
          </div>

          <div className="single-info-container">
            <div className="single-info-title">
              <span className="font-weight-bold">Faux phishing envoyé</span>
            </div>
            <div className="single-info-content">
              <span className="font-family-nunito-variablefont">
                {newCampaignElements.templateName}
              </span>
            </div>
          </div>

          <div className="single-info-container">
            <div className="align-self-start single-info-title">
              <span className="font-weight-bold">
                Emails ciblés par la campagne
              </span>
            </div>
            <div className="emails-list-container">
              {newCampaignElements.emailsList.map((entry) => (
                <>
                  <div className="emails-listing">{entry.email}</div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignSetUpControlV2;
