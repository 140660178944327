import React, { useState } from "react";
import YourCampaignSummaryV2 from "./YourCampaignSummaryV2";
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropdownCircle,
  IoMdPause,
} from "react-icons/io";

const YourCampaignDetailsV2 = ({ campaign }) => {
  const [displaySummary, setDisplaySummary] = useState(false);
  const [today] = useState(new Date());
  const launchDate = new Date(campaign.launch_date);

  return (
    <>
      <div className="campaign-details-container">
        <div className="detail-container">
          {displaySummary ? (
            <>
              <IoIosArrowDropdownCircle
                size="1.5em"
                className="blinking cursor-pointer"
                onClick={() => setDisplaySummary(false)}
              />
            </>
          ) : (
            <>
              {today < launchDate ? (
                <>
                  <IoMdPause size="1.5em" color="orange" />
                </>
              ) : (
                <>
                  <IoIosArrowDroprightCircle
                    size="1.5em"
                    className="blinking cursor-pointer"
                    onClick={() => setDisplaySummary(true)}
                  />
                </>
              )}
            </>
          )}
        </div>
        <div className="detail-container">{campaign.name}</div>

        <div className="detail-container">
          {new Date(campaign.launch_date).toLocaleDateString("fr-FR", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>

        <div>{campaign.template}</div>
      </div>

      <div className="detail-container">
        {displaySummary && (
          <>
            <YourCampaignSummaryV2 gophishId={campaign.gophish_id} />
          </>
        )}
      </div>
    </>
  );
};

export default YourCampaignDetailsV2;
