import Cookies from "js-cookie";
import React from "react";
import { Outlet } from "react-router-dom";
import { signOut } from "../../../utils/functions";

function ProtectedRoutes() {
  const isSessionOpen = localStorage.getItem("AuthUserData") && Cookies.get("token");

  const forceSignOut = () => {
    alert(
      "Connexion interrompue.\nVeuillez vous reconnecter."
    );
    signOut();
  };

  return <>{isSessionOpen ? <Outlet /> : forceSignOut()}</>;
}

export default ProtectedRoutes;
